import * as Yup from "yup";

const validateDataForNotificationType = function (value) {
  const { notificationGroupIds, toSettings, ccSettings, bccSettings } = value;
  const hasNotificationGroupIds = notificationGroupIds?.length > 0;

  // Check if "other" has a value in any of the settings
  const hasOtherInSettings =
    toSettings?.other?.length > 0 ||
    ccSettings?.other?.length > 0 ||
    bccSettings?.other?.length > 0;

  if (!!hasNotificationGroupIds || !!hasOtherInSettings) {
    return true;
  } else {
    return this.createError({
      path: "notificationGroupIds",
      message:
        "Either notificationGroupIds must have a value, or 'other' must be present in one of the settings",
    });
  }
};

export const additionalEmailSettingsSchema = (
  emailReceiversParams,
  emailType
) => {
  let hrSchema = Yup.array()
    .nullable()
    .test({
      name: "hr",
      test: (hr) => hr === null || (Array.isArray(hr) && hr.length > 0),
      message: "Field is required",
      exclusive: true,
    });

  let opsSchema = Yup.array()
    .nullable()
    .test({
      name: "ops",
      test: (ops) => ops === null || (Array.isArray(ops) && ops.length > 0),
      message: "Field is required",
      exclusive: true,
    });

  let otherSchema = Yup.array()
    .nullable()
    .of(
      Yup.string().email("Invalid email address").required("Field is required")
    );

  let frequencySchema = Yup.array().of(
    Yup.object().shape({
      value: Yup.number()
        .integer("Please enter a valid number")
        .min(1, "Value must be greater than 0!")
        .max(10000, "Please enter a practical number")
        .nullable()
        .typeError("Field is required!")
        .required("Field is required!"),
      unit: Yup.number()
        .nullable()
        .typeError("Field is required!")
        .required("Field is required!"),
    })
  );

  let notificationSchema = Yup.array();

  if (emailType === 2) {
    hrSchema = hrSchema.notRequired();
    opsSchema = opsSchema.notRequired();
    // otherSchema = otherSchema.notRequired();
    frequencySchema = Yup.array().of(
      Yup.object().shape({
        value: Yup.number()
          .integer("Please enter a valid number")
          .min(1, "Value must be greater than 0!")
          .max(10000, "Please enter a practical number")
          .nullable()
          .typeError("Field is required!")
          .notRequired(),
        unit: Yup.number()
          .nullable()
          .typeError("Field is required!")
          .notRequired(),
      })
    );
  }

  let settingsSchema = {};

  emailReceiversParams.forEach((param) => {
    settingsSchema[param?.value] = Yup.object().shape({
      hr: hrSchema,
      ops: opsSchema,
      other: otherSchema,
    });
  });

  let finalSchema = Yup.object().shape({
    emailCategoryId: Yup.number()
      .typeError("Field is required!")
      .required("Field is required!"),
    frequency: frequencySchema,
    notificationGroupIds: notificationSchema,
    ...settingsSchema,
  });

  if (emailType === 2) {
    finalSchema = finalSchema.test(
      "validateDataForNotificationType",
      "Either notificationGroupIds must have a value, or 'other' must be present in one of the settings",
      validateDataForNotificationType
    );
  }

  return finalSchema;
};
