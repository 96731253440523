import * as Yup from "yup";
import { createSectionSchema } from "../../utils/createSectionSchema";
import { educationFieldsSchema } from "./allFieldsSchema";

export const educationValidationSchema = (inputFields, initialValues) => {
  //mapping initialValues field name to that of inputFields field name so that their schema can be included.
  let mappingSchemaFieldsToDisplayInputFields = {
    qualificationTypeOthers: "qualificationTypeId",
    otherEducationType: "educationTypeId",
    graduationDate: "isCurrentlyStudying",
  };

  let eduSchema = createSectionSchema(
    initialValues[0],
    inputFields,
    educationFieldsSchema,
    mappingSchemaFieldsToDisplayInputFields
  );

  return Yup.object({
    qualifications: Yup.array().of(Yup.object().shape(eduSchema)),
  });
};
