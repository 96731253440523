export const declarationHeaders = () => {
  return [
    {
      value: "question",
      text: "Question",
    },
    {
      value: "answer",
      text: "Answer",
    },
  ];
};
