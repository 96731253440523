import { uid } from "../../../base";
import { createSectionInitialDetailsFields } from "../../utils/createSectionInitialDetailsFields";

export const newNickName = () => {
  return {
    lastName: "",
    firstName: "",
    middleName: "",
    isExpanded: true,
  };
};

export const newFormerName = () => {
  return {
    formerName: "",
    formerNameDateChange: "",
    id: uid(10),
  };
};

export const newOtherName = () => {
  return {
    otherName: "",
    pinyinName: "",
    otherNameLanguageId: null,
    id: uid(10),
  };
};

export const newPersonalParticular = (inputFields) => {
  let allFields = {
    firstName: "",
    middleName: "",
    lastName: "",
    dateOfBirth: "",
    countryOfBirthId: null,
    gender: "M",
    personalEmail: "",
    mobileNumber: { 
      mobileNumberCountryCode: null,
      mobileNumber: "",
    },
    otherNameExists: { otherNameExists: false, otherNames: [newOtherName()] },
    formerNameExists: {
      formerNameExists: false,
      formerNames: [newFormerName()],
    },
    nickNameExists: { nickNameExists: false, nickName: [newNickName()] },
    DINNumberExists: {
      DINNumberExists: false,
      DINNumber: null,
    },
    driverLicenseNumberExists: {
      driverLicenseNumberExists: false,
      driverLicenseNumber: null,
      driverLicenseFrontCopy: null,
      driverLicenseBackCopy: null,
    },
  };

  return createSectionInitialDetailsFields(inputFields, allFields);
};
