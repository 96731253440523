import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  getAllInputFields,
  getAllInputFieldsByCriteria,
  getCandidateProfileSections,
  updateInputFields,
} from "../../../../store/actions/systemAdminActions";
import { getCurrentFileNameAndFunction } from "../../../../utils/getCurrentFileNameAndFunction";
import { getAllCountries } from "../../../../store/actions/helperActions";
import { getAllChecks } from "../../../../store/actions/hrActions";
import CircularLoader from "../../../../common/CircularLoader";
import SelectedSectionFieldsSettings from "./components/SelectedSectionFieldsSettings";
import CustomTooltip from "./../../../common/CustomTooltip";
import {
  SET_DEFAULT_INPUT_FIELDS_ON_CLICK,
  UPDATE_DYNAMIC_INPUT_FIELDS_SETTINGS,
} from "../../../../store/actions/actionTypes";

const DynamicInputFields = () => {
  const [researchCountryId, setResearchCountryId] = useState(null);
  const [checkId, setCheckId] = useState(null);
  const [candidatesProfileSectionId, setCandidatesProfileSectionId] =
    useState(null);
  const [defaultSetting, setDefaultSetting] = useState(false);

  const dispatch = useDispatch();

  const {
    selectedOrg,
    candidateProfileSectionsLists,
    allInputFields,
    allInputFieldsByCriteria,
    defaultInputFieldsByCriteria,
    overlappingCountryForChecks,
    dynamicInputFields,
  } = useSelector((state) => state.systemAdmin);
  const { allChecksData } = useSelector((state) => state?.hr);
  const { allCountries } = useSelector((state) => state.helper);

  useEffect(() => {
    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "useEffect-empty-dependency"
    );
    dispatch(getAllChecks(logDetails));
    dispatch(getAllCountries(logDetails));
    dispatch(getCandidateProfileSections(logDetails));
  }, []);

  //setting initial selected profile section to the first value
  useEffect(() => {
    if (!candidateProfileSectionsLists?.length) return;

    handleSectionSelect(
      candidateProfileSectionsLists[0]?.candidatesProfileSectionsId
    );
  }, [candidateProfileSectionsLists]);

  //on change of org, country, checkId or section, calling input fields by criteria api
  useEffect(() => {
    handleFetchInputFieldsByCriteria();
  }, [selectedOrg, researchCountryId, checkId, candidatesProfileSectionId]);

  //setting dynamic inputfields
  useEffect(() => {
    //resetting dynamic fields
    dispatch({
      type: UPDATE_DYNAMIC_INPUT_FIELDS_SETTINGS,
      payload: [],
    });

    let inputFieldsToDisplay = [];

    if (!allInputFields?.length || allInputFieldsByCriteria === null)
      return inputFieldsToDisplay;

    allInputFields?.forEach((curr) => {
      let tempInputFieldsByCriteria;
      if (allInputFieldsByCriteria?.length) {
        tempInputFieldsByCriteria = [...allInputFieldsByCriteria];
        setDefaultSetting(false);
      } else {
        setDefaultSetting(true);
        tempInputFieldsByCriteria = [...defaultInputFieldsByCriteria];
      }

      let isPresent = tempInputFieldsByCriteria?.find(
        (ele) => curr?.id === ele?.candidateProfileInputFieldId
      );

      let finalObj = {
        ...curr,
        isMandatory: false,
        toDisplay: false,
      };

      if (isPresent) {
        inputFieldsToDisplay.push({
          ...finalObj,
          ...isPresent,
          toDisplay: true,
        });
      } else {
        inputFieldsToDisplay.push(finalObj);
      }

      defaultInputFieldsByCriteria.forEach((def) => {
        inputFieldsToDisplay.forEach((ifd) => {
          if (
            (ifd?.candidateProfileInputFieldId || ifd.id) ===
              def?.candidateProfileInputFieldId &&
            ifd?.isMandatory !== def?.isMandatory
          ) {
            ifd.isConflict = true;
          }
        });
      });
    });

    dispatch({
      type: UPDATE_DYNAMIC_INPUT_FIELDS_SETTINGS,
      payload: inputFieldsToDisplay,
    });
  }, [allInputFields, allInputFieldsByCriteria]);

  //adding 'all' option to countries lists
  let countriesLists = useMemo(() => {
    if (!allCountries?.length) return [];
    // allCountries.forEach((country) => {
    //   if (overlappingCountryForChecks?.includes(country.countryMasterId)) {
    //     country.overlappSettingExists = true;
    //   }
    // });
    return [{ countryMasterId: "all", name: "All" }, ...allCountries];
  }, [allCountries, overlappingCountryForChecks]);

  //handles selection of profile section and calling all input fields api
  const handleSectionSelect = (sectionId) => {
    if (candidatesProfileSectionId === sectionId) return;

    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "handleSectionSelect"
    );

    dispatch(
      getAllInputFields(logDetails, {
        sectionId,
      })
    );
    setCandidatesProfileSectionId(sectionId);
  };

  //handles calling of input fields by criteria api
  const handleFetchInputFieldsByCriteria = useCallback(() => {
    let finalParams = {
      candidatesProfileSectionId,
      checkId,
      hrOrganizationId: selectedOrg,
      researchCountryId,
    };

    finalParams.researchCountryId =
      finalParams?.researchCountryId === "all"
        ? null
        : finalParams?.researchCountryId;

    if (!finalParams?.checkId || !finalParams?.candidatesProfileSectionId) {
      return;
    }

    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "handleFetchInputFieldsByCriteria"
    );

    dispatch(getAllInputFieldsByCriteria(logDetails, finalParams));
  }, [selectedOrg, researchCountryId, checkId, candidatesProfileSectionId]);

  //handles on click of update fields btn
  const handleUpdateFields = () => {
    let params = {
      checkId,
      candidatesProfileSectionId,
      hrOrganizationId: selectedOrg,
      researchCountryId: researchCountryId === "all" ? null : researchCountryId,
    };

    let payload = [];

    dynamicInputFields?.forEach((field) => {
      delete field?.isDefault;
      if (field?.toDisplay) {
        payload.push({
          candidateProfileInputFieldId:
            field?.candidateProfileInputFieldId || field?.id,
          isMandatory: field?.isMandatory,
        });
      }
    });

    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "handleUpdateFields"
    );
    setDefaultSetting(false);
    dispatch(updateInputFields(logDetails, { inputFields: payload }, params));
  };

  const handleDefaultFields = () => {
    dispatch({
      type: SET_DEFAULT_INPUT_FIELDS_ON_CLICK,
      payload: defaultInputFieldsByCriteria,
    });
  };

  return (
    <>
      {candidateProfileSectionsLists?.length &&
      countriesLists?.length &&
      allChecksData?.checks?.length ? (
        <Grid container>
          <Grid
            container
            item
            xs={12}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
            wrap="nowrap" // Prevent wrapping
            overflow="auto" // Allow horizontal scrolling if necessary
          >
            {/* Left section with dropdowns */}
            <Grid
              item
              container
              xs={12}
              md={6}
              spacing={2}
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item xs={12} md={6} mt={1}>
                <Autocomplete
                  disablePortal
                  disableClearable
                  id="check-selection"
                  options={allChecksData?.checks}
                  disabled={!allChecksData?.checks?.length}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setCheckId(newValue.checksId);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Check" />
                  )}
                  getOptionLabel={(option) => option.checkName || ""}
                  filterOptions={(options, { inputValue }) =>
                    inputValue?.length
                      ? options?.filter((option) =>
                          option.checkName
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        )
                      : options
                  }
                  renderOption={(props, item) => (
                    <li {...props} key={item.checksId}>
                      <Box>
                        {item?.checkName?.length > 35 ? (
                          <CustomTooltip
                            tooltipmaxwidth={400}
                            title={item?.checkName}
                          >
                            <Box component="span">{`${item?.checkName.slice(
                              0,
                              30
                            )}...`}</Box>
                          </CustomTooltip>
                        ) : (
                          item?.checkName
                        )}
                      </Box>
                    </li>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} mt={1}>
                <Autocomplete
                  disablePortal
                  disableClearable={researchCountryId !== "all"}
                  id="country-selection"
                  options={countriesLists}
                  defaultValue={countriesLists[0]}
                  disabled={!countriesLists?.length}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setResearchCountryId(newValue.countryMasterId);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Country" />
                  )}
                  getOptionLabel={(option) => option.name || ""}
                  filterOptions={(options, { inputValue }) =>
                    inputValue?.length
                      ? options?.filter((option) =>
                          option.name
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        )
                      : options
                  }
                  renderOption={(props, item) => (
                    <Box
                      {...props}
                      key={item.countryMasterId}
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        srcSet={`https://flagcdn.com/w40/${item?.iso?.toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${item?.iso?.toLowerCase()}.png`}
                        alt=""
                      />
                      {item?.name?.length > 35 ? (
                        <CustomTooltip tooltipmaxwidth={400} title={item?.name}>
                          <Box component="span">{`${item?.name.slice(
                            0,
                            30
                          )}...`}</Box>
                        </CustomTooltip>
                      ) : (
                        item?.name
                      )}
                    </Box>
                  )}
                />
              </Grid>
            </Grid>

            {/* Right section with buttons */}
            <Grid
              item
              container
              xs={12}
              md={6}
              spacing={2}
              alignItems="center"
              justifyContent="flex-end"
              wrap="nowrap" // Prevent wrapping in the button section
              sx={{ display: "flex" }}
            >
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  disableElevation
                  onClick={handleDefaultFields}
                  disabled={!checkId}
                >
                  Reset to Default
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  disableElevation
                  onClick={handleUpdateFields}
                  disabled={!checkId}
                  sx={{
                    backgroundColor: (theme) =>
                      defaultSetting && theme.palette.warning.dark,
                  }}
                >
                  Update Input Fields
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {candidateProfileSectionsLists && checkId ? (
            <Grid
              container
              xs={12}
              mt={2}
              height={{ xs: "60vh", xl: "65vh", xxl: "70vh" }}
            >
              <Grid
                item
                xs={3}
                border={(theme) => `1px solid ${theme.palette.grey[400]}`}
                height="100%"
              >
                {candidateProfileSectionsLists?.map((profile) => (
                  <Box
                    key={profile?.candidatesProfileSectionsId}
                    p={1}
                    borderBottom={(theme) =>
                      `1px solid ${theme.palette.grey[400]}`
                    }
                    sx={{
                      backgroundColor:
                        candidatesProfileSectionId ===
                        profile?.candidatesProfileSectionsId
                          ? (theme) => theme.palette.primary[100]
                          : "none",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleSectionSelect(profile?.candidatesProfileSectionsId);
                    }}
                  >
                    <Typography
                      fontWeight={
                        candidatesProfileSectionId ===
                        profile?.candidatesProfileSectionsId
                          ? 600
                          : 550
                      }
                      fontSize="12px"
                    >
                      {profile?.candidateProfileSectionDisplayName}
                    </Typography>
                  </Box>
                ))}
              </Grid>
              <Grid
                item
                xs={9}
                border={(theme) => `1px solid ${theme.palette.grey[400]}`}
                borderLeft="none"
                height="100%"
                overflow="auto"
              >
                {dynamicInputFields?.length ? (
                  <SelectedSectionFieldsSettings
                    candidatesProfileSectionId={candidatesProfileSectionId}
                  />
                ) : (
                  <CircularLoader size={25} height="40vh" />
                )}
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      ) : (
        <CircularLoader />
      )}
    </>
  );
};

export default DynamicInputFields;
