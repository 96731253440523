import Heading from "./components/Heading";
import HistoryLog from "./components/HistoryLog";
import UpdateActionComponent from "./components/UpdateActionComponent";
import VerifierDetails from "./components/VerifierDetails";
import ViewFilledDetailsAndUpdateVerifierDetails from "./components/ViewFilledDetailsAndUpdateVerifierDetails";
import { uniqueId } from "lodash";

const SelectedSubCheckDetails = () => {
  return (
    <>
      <Heading />
      <UpdateActionComponent id={uniqueId()} />
      <VerifierDetails id={uniqueId()} />
      <ViewFilledDetailsAndUpdateVerifierDetails />
      <HistoryLog />
    </>
  );
};

export default SelectedSubCheckDetails;
