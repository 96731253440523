import {
  Box,
  Button,
  TextField,
  TextareaAutosize,
  Typography,
  styled,
} from "@mui/material";
import CheckStatusDropdown from "../../../common/CheckStatusDropdown";
import DropdownComponent from "../../../common/DropdownComponent";
import { useCallback, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DisplayChip from "../../../common/DisplayChip";
import { useEffect } from "react";
import { updateSubCheckData } from "../../../../../../../../../store/actions/operationActions";
import { useSearchParams } from "react-router-dom";
import { setToastNotification } from "../../../../../../../../../store/actions/toastNotificationActions";
import { ERROR } from "../../../../../../../../../store/constant";
import { checkActionPermission } from "../../../../../../../../../utils/CheckPageAccess";
import permissionKey from "../../../../../../../../constants/permissionKey";
import { removeTimeFromDate } from "../../../../../../../../Candidate/utils/removeTimeFromDate";
import { FastField, Form, Formik, setNestedObjectValues } from "formik";
import { BaseDatePicker, BaseTextField } from "../../../../../../../../base";
import { getCurrentFileNameAndFunction } from "../../../../../../../../../utils/getCurrentFileNameAndFunction";
import CircularLoader from "../../../../../../../../../common/CircularLoader";
import { cloneDeep, isEqual, omit } from "lodash";
import ActionLog from "./ActionLog";
import ActionLogFileUpload from "./innerComponents/ActionLogFileUpload";
import { removingExtraFields } from "./utils/removingExtraFields";
import { replacingDropdownId } from "./utils/replacingDropdownId";
import { removeEmptyObjects } from "./utils/removeEmptyObjects";
import { checkFromDateToDate } from "./utils/checkFromDateToDate";
import { formatDateValues } from "./utils/formatDateValues";
import {
  GET_ACTION_LOG_ADDITIONAL_CATEGORIES,
  GET_ACTION_LOG_CATEGORIES_INPUTS,
  GET_ACTION_LOG_SUB_CATEGORIES,
} from "../../../../../../../../../store/actions/actionTypes";

const Textarea = styled(TextareaAutosize)(
  ({ theme }) => `
  width:100%;
  max-width:100%;
  font-family:inherit;
  overflow:auto !important;
  font-weight:500;
  box-sizing: border-box;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid ${theme.palette.grey[400]};

  &:hover {
    border: 1px solid #000;
  }

  &:focus {
    border: 1.5px solid ${theme.palette.primary.main};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const UpdateActionComponent = ({ id }) => {
  const [disableBtn, setDisableBtn] = useState(true);
  const [internalStatusDropdown, setInternalStatusDropdown] = useState({});
  const [riskLevelDropdown, setRiskLevelDropdown] = useState({});
  const [selectedAssignee, setSelectedAssignee] = useState({});
  const [loadingUpdateBtn, setLoadingUpdateBtn] = useState(false);
  const [searchParams, _] = useSearchParams();

  const formRef = useRef();

  //action log form refs
  const categoryDropdownFormRef = useRef();
  const categoryInputsFormRef = useRef();
  const fileUploadFormRef = useRef();

  const dispatch = useDispatch();

  const {
    checkInternalStatusLists,
    verificationResultStatusData,
    OpsUserBySubRoleIds,
    subChecksList,
    selectedSubCheckId,
    OpsCandidateCaseChecksList,
    selectedCheckId,
    actionLogCategoriesInputs,
  } = useSelector((state) => state.operations);

  const { candidateDetailsById } = useSelector((state) => state.hr);

  const { permissions } = useSelector((state) => state.authorization);

  const { currencies } = useSelector((state) => state.candidate);

  let selectedSubCheck = useMemo(() => {
    return subChecksList?.find((curr) => curr.id === selectedSubCheckId);
  }, [subChecksList, selectedSubCheckId]);

  let rowData = useMemo(() => {
    return OpsCandidateCaseChecksList?.find(
      (curr) => curr?.candidatesChecksMappingId === selectedCheckId
    );
  }, [OpsCandidateCaseChecksList, selectedCheckId]);

  useEffect(() => {
    return () => {
      formRef.current?.resetForm();
      fileUploadFormRef.current?.resetForm();
    };
  }, [selectedCheckId, selectedSubCheck?.id]);

  useEffect(() => {
    initializeDropdownValues();
  }, [
    selectedSubCheck,
    subChecksList,
    selectedSubCheckId,
    checkInternalStatusLists,
    verificationResultStatusData,
  ]);

  let pdfFieldsArr = useMemo(
    () => [
      {
        label: "Entity Name",
        field: "entityName",
        display: rowData?.checkTypeName === "Verification",
      },
      {
        label: "Date of Search",
        field: "dateOfSearch",
        display: rowData?.checkTypeName !== "Verification",
      },
      {
        label: "Source",
        field: "source",
        display: rowData?.checkTypeName !== "Verification",
      },
    ],
    [rowData]
  );

  let pdfFieldsInitialValues = useMemo(() => {
    //pdf fields
    let tempPdfField = {
      // checkSummary:
      //   selectedSubCheck?.checkSummary || selectedSubCheck?.checkStatusName,
      checkSummary: selectedSubCheck?.checkSummary || "",
      source: selectedSubCheck?.source,
      dateOfSearch: selectedSubCheck?.dateOfSearch || new Date(),
      checkResult: selectedSubCheck?.checkResult || "",
    };

    //adding value to 'entityName'
    if (rowData?.checkTypeName === "Verification" && candidateDetailsById) {
      let checkIdToCandidateDetailsMapping = {
        11: {
          //education check
          candidateDetailsKey: "EDUCATIONAL_QUALIFICATIONS",
          toMatchWithId: "candidatesEducationsId",
          subcheckValueField: "nameOfSchoolCollegeUniversity",
        },
        12: {
          //emp check
          candidateDetailsKey: "EMPLOYMENT_HISTORY",
          toMatchWithId: "candidatesEmploymentsId",
          subcheckValueField: "companyName",
        },
        13: {
          //salary check
          candidateDetailsKey: "EMPLOYMENT_HISTORY",
          toMatchWithId: "candidatesEmploymentsId",
          subcheckValueField: "companyName",
        },
        14: {
          //prof license check
          candidateDetailsKey: "PROFESSIONAL_QUALIFICATIONS",
          toMatchWithId: "candidatesProfessionalQualificationsId",
          subcheckValueField: "professionalQualificationTitle",
        },
        15: {
          //ref check
          candidateDetailsKey: "PROFESSIONAL_REFERENCE",
          toMatchWithId: "candidatesProfessionalReferencesDetailsId",
          subcheckValueField: "fullName",
        },
      };

      let { candidateDetailsKey, toMatchWithId, subcheckValueField } =
        checkIdToCandidateDetailsMapping[rowData?.checkId];

      let sectionDetails =
        candidateDetailsKey === "EMPLOYMENT_HISTORY"
          ? candidateDetailsById[candidateDetailsKey]?.candidatesEmployeeHistory
          : candidateDetailsKey === "PROFESSIONAL_REFERENCE"
          ? candidateDetailsById[candidateDetailsKey][0]?.referencedetails
          : candidateDetailsById[candidateDetailsKey];

      let matchedDetails = sectionDetails?.find(
        (curr) => curr[toMatchWithId] === selectedSubCheck?.detailsMappingId
      );

      if (matchedDetails && !selectedSubCheck?.entityName) {
        tempPdfField.entityName = matchedDetails[subcheckValueField];
      } else {
        tempPdfField.entityName = selectedSubCheck?.entityName;
      }
    }

    return tempPdfField;
  }, [rowData, selectedSubCheck, candidateDetailsById]);

  const initializeDropdownValues = useCallback(() => {
    //disabling btn.Will also get disabled when cancel btn is clicked
    setDisableBtn(true);

    //internal check status
    if (selectedSubCheck?.subCheckInternalStatusId) {
      setInternalStatusDropdown({
        [selectedSubCheck?.id]: selectedSubCheck?.subCheckInternalStatusId,
      });
    } else {
      setInternalStatusDropdown({
        [selectedSubCheck?.id]: checkInternalStatusLists[0]?.id,
      });
    }

    //risk level
    if (selectedSubCheck?.subCheckVerificationResultStatusId) {
      setRiskLevelDropdown({
        [selectedSubCheck?.id]:
          selectedSubCheck?.subCheckVerificationResultStatusId,
      });
    } else {
      setRiskLevelDropdown({
        [selectedSubCheck?.id]: "",
      });
    }

    //assignee
    if (selectedSubCheck?.assignee) {
      setSelectedAssignee({
        [selectedSubCheck?.id]: selectedSubCheck?.assignee,
      });
    } else {
      setSelectedAssignee({
        [selectedSubCheck?.id]: "",
      });
    }

    //resetting form
    formRef.current?.setValues(pdfFieldsInitialValues);
  }, [id, selectedSubCheck?.id, formRef.current, pdfFieldsInitialValues]);

  //handling action log fields submition
  const handleActionLogSubmition = async () => {
    //category dropdown fields
    let {
      validateForm: categoryDropdownValidateForm,
      values: dropdownFormValues,
      resetForm: dropdownResetForm,
    } = categoryDropdownFormRef.current;

    let { categoryIdL1, categoryIdL2, categoryIdL3 } = dropdownFormValues;

    let categoryDropdownFormErrors = await categoryDropdownValidateForm();

    //if any category dropdown is not selected then we will simply return by touching all the dropdowns
    if (
      categoryDropdownFormErrors &&
      Object.keys(categoryDropdownFormErrors)?.length
    ) {
      categoryDropdownFormRef.current.setTouched(
        setNestedObjectValues(categoryDropdownFormErrors, true)
      );

      return null;
    }

    //category input fields
    let {
      validateForm,
      values,
      setTouched,
      resetForm: categoryInputsResetForm,
    } = categoryInputsFormRef.current;

    //validating category input fields
    let errors = await validateForm();
    let validationErrors = cloneDeep(errors);
    let categoryInputValues = cloneDeep(values);

    //remove extra fields from values & errors
    removingExtraFields(
      validationErrors,
      categoryInputValues,
      actionLogCategoriesInputs
    );

    //replacing selected dropdown id with respective string
    replacingDropdownId(
      actionLogCategoriesInputs,
      categoryInputValues,
      currencies
    );

    //Remove empty objects
    removeEmptyObjects(categoryInputValues);
    removeEmptyObjects(validationErrors);

    //returns when there is any error present in the category input fields
    if (Object.keys(validationErrors)?.length) {
      //since we are submitting our form on 'onClick' event on next btn fields wont get touched
      //so we have to explicitly touch them so that validation error msgs below fields can get displayed
      //and this can be achieved by using 'setTouched' &  'setNestedObjectValues' which is provided by formik
      setTouched(setNestedObjectValues(validationErrors, true));

      return null;
    }

    //uploaded files values
    let { values: fileUploadValues, resetForm: fileUploadResetForm } =
      fileUploadFormRef.current;

    //structuring payload layout
    let payload = {
      categoryIdL1,
      categoryIdL2,
      categoryIdL3,
      subCheckId: selectedSubCheck?.id,
      logDetails: categoryInputValues,
      attachments: fileUploadValues?.files?.map((file) => {
        file.attachFileToReport =
          (fileUploadValues?.attachFileToReport &&
            fileUploadValues?.attachFileToReport[file?.attachmentName]) ||
          false;

        return omit(file, ["status"]);
      }),
    };

    //to check if from date is less than to date or not
    let fromDateisGreaterThanToDate = checkFromDateToDate(payload?.logDetails);

    if (fromDateisGreaterThanToDate) {
      dispatch(
        setToastNotification(ERROR, "from date should be less than to date")
      );

      return null;
    }

    // Compare 'conductedDate' and 'respondedDate' dates
    if (
      payload?.logDetails["Conducted Date"] &&
      payload?.logDetails["Respond Received Date"]
    ) {
      const conductedDate = new Date(payload?.logDetails["Conducted Date"]);
      const respondedDate = new Date(
        payload?.logDetails["Respond Received Date"]
      );

      if (conductedDate >= respondedDate) {
        dispatch(
          setToastNotification(
            ERROR,
            "conducted date should be less than respond received date"
          )
        );

        return null;
      }
    }

    //updating date type field values with appropriate formats
    formatDateValues(payload?.logDetails, actionLogCategoriesInputs);

    dropdownResetForm();
    categoryInputsResetForm();
    fileUploadResetForm();
    dispatch({
      type: GET_ACTION_LOG_SUB_CATEGORIES,
      payload: [],
    });
    dispatch({
      type: GET_ACTION_LOG_ADDITIONAL_CATEGORIES,
      payload: [],
    });
    dispatch({
      type: GET_ACTION_LOG_CATEGORIES_INPUTS,
      payload: [],
    });

    return payload;
  };

  const handleUpdate = async () => {
    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "handleUpdate"
    );
    // if (!riskLevelDropdown[selectedSubCheck?.id]) {
    //   return dispatch(
    //     setToastNotification(ERROR, "Please select a valid risk level",logDetails)
    //   );
    // }

    if (!selectedAssignee[selectedSubCheck?.id]) {
      return dispatch(
        setToastNotification(
          ERROR,
          "Please select a valid assignee",
          logDetails
        )
      );
    }

    let actionLog = await handleActionLogSubmition();

    if (!actionLog) return;

    let payload = {
      assignee: selectedAssignee[selectedSubCheck?.id],
      subCheckInternalStatusId: internalStatusDropdown[selectedSubCheck?.id],
      subCheckVerificationResultStatusId:
        riskLevelDropdown[selectedSubCheck?.id] || null,
      checkSummary: formRef.current?.values?.checkSummary || null,
      checkResult: formRef.current?.values?.checkResult || null,
      actionLog: actionLog?.categoryIdL1 ? actionLog : null,
    };

    pdfFieldsArr?.forEach((curr) => {
      if (curr?.display) {
        payload = {
          ...payload,
          [curr?.field]:
            curr?.field === "dateOfSearch"
              ? removeTimeFromDate(formRef.current?.values[curr?.field]) || null
              : formRef.current?.values[curr?.field] || null,
        };
      }
    });

    //after update call , making update btn disabled again
    setDisableBtn(true);

    dispatch(
      updateSubCheckData(
        payload,
        rowData.candidatesChecksMappingId,
        selectedSubCheck?.id,
        +searchParams.get("candidatesCasesId"),
        initializeDropdownValues,
        logDetails,
        setLoadingUpdateBtn
      )
    );
  };

  return (
    <Formik
      key={selectedSubCheck?.id}
      enableReinitialize
      initialValues={pdfFieldsInitialValues}
      innerRef={formRef}
    >
      {(form) => {
        useEffect(() => {
          //check result & check summary are non-mandatory so only for checking the difference below and not their value will suffice
          setDisableBtn(isEqual(form?.values, pdfFieldsInitialValues));
        }, [form?.values, rowData, pdfFieldsInitialValues]);

        return (
          <Form>
            <Box
              display="flex"
              alignItems="flex-start"
              p={2}
              pb={0}
              gap={1}
              position="relative"
            >
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="flex-start"
                flexDirection="column"
                gap={1}
                flexGrow={1}
              >
                {" "}
                {/*internal status - check status */}
                <Box display="flex" alignItems="center" width="100%" pl={1}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography
                      fontWeight={550}
                      textTransform="capitalize"
                      letterSpacing="1px"
                      textAlign="center"
                      mb={1}
                      fontSize="12px"
                    >
                      internal status
                    </Typography>
                    <CheckStatusDropdown
                      id={selectedSubCheck?.id}
                      options={checkInternalStatusLists}
                      optionLabel="checkInternalStatusName"
                      optionId="id"
                      defaultValue={
                        internalStatusDropdown[selectedSubCheck?.id]
                      }
                      setDisableBtn={setDisableBtn}
                      setDropdownVal={setInternalStatusDropdown}
                      updateCheckSummary={(id) => {
                        let foundCheck = checkInternalStatusLists?.find(
                          (curr) => curr.id === id
                        );

                        form?.setFieldValue(
                          "checkSummary",
                          foundCheck?.checkStatusName
                        );
                      }}
                      updateDisableStateDirectly={true}
                    />
                  </Box>
                  <Box mt={1} flexGrow={1}>
                    <Typography
                      fontWeight={550}
                      textTransform="capitalize"
                      letterSpacing="1px"
                      textAlign="center"
                      mb={1}
                      fontSize="12px"
                    >
                      check status
                    </Typography>
                    {checkInternalStatusLists && (
                      <DisplayChip
                        key={selectedSubCheck?.id}
                        id={selectedSubCheck?.id}
                        rowData={selectedSubCheck}
                        checkStatusDropdown={internalStatusDropdown}
                        checkInternalStatusLists={checkInternalStatusLists}
                      />
                    )}
                  </Box>
                </Box>
                {/*risk level - check summary */}
                <Box display="flex" alignItems="center" width="100%">
                  <Box>
                    <Typography
                      fontWeight={550}
                      textTransform="capitalize"
                      letterSpacing="1px"
                      textAlign="center"
                      mb={1}
                      fontSize="12px"
                    >
                      risk level
                    </Typography>
                    <DropdownComponent
                      id={selectedSubCheck?.id}
                      options={verificationResultStatusData}
                      optionLabel="verificationResultStatusName"
                      optionId="candidatesVerificationResultStatusId"
                      defaultValue={riskLevelDropdown[selectedSubCheck?.id]}
                      placeholderText="Select Risk"
                      setDisableBtn={setDisableBtn}
                      setDropdownVal={setRiskLevelDropdown}
                      displayIcon={true}
                      updateDisableStateDirectly={true}
                    />
                  </Box>
                  <Box ml={1.5} width="100%">
                    <Typography
                      fontWeight={550}
                      textTransform="capitalize"
                      letterSpacing="1px"
                      textAlign="center"
                      mb={1}
                      fontSize="12px"
                    >
                      Check Summary
                    </Typography>
                    <FastField
                      key={id}
                      component={TextField}
                      value={form?.values?.checkSummary || ""}
                      type="text"
                      size="small"
                      fullWidth
                      InputProps={{
                        sx: {
                          height: "32px",
                        },
                      }}
                      variant="outlined"
                      onChange={(e) =>
                        form?.setFieldValue("checkSummary", e.target.value)
                      }
                    />
                  </Box>
                </Box>
                {/*date of search - source - entity name */}
                <Box display="flex" alignItems="center" width="100%" pl={1}>
                  {pdfFieldsArr?.map((pdfField, ind) =>
                    pdfField?.display ? (
                      <Box
                        key={ind}
                        width={
                          pdfField?.field === "dateOfSearch" ? "auto" : "100%"
                        }
                        mr={pdfField?.field === "dateOfSearch" ? 2 : 0}
                      >
                        <Typography
                          fontWeight={550}
                          textTransform="capitalize"
                          letterSpacing="1px"
                          textAlign={
                            pdfField?.field === "dateOfSearch"
                              ? "left"
                              : "center"
                          }
                          mb={1}
                          fontSize="12px"
                        >
                          {pdfField?.label}
                        </Typography>
                        {pdfField?.field === "dateOfSearch" ? (
                          <FastField
                            component={BaseDatePicker}
                            name="dateOfSearch"
                            label=""
                            views={["year", "month", "day"]}
                            placeholder="DD-MMM-YYYY"
                            inputFormat="dd-MMM-yyyy"
                            value={form?.values[pdfField?.field] || ""}
                          />
                        ) : (
                          <FastField
                            component={TextField}
                            value={form?.values[pdfField?.field] || ""}
                            type="text"
                            size="small"
                            fullWidth
                            InputProps={{
                              sx: {
                                height: "32px",
                              },
                            }}
                            variant="outlined"
                            onChange={(e) =>
                              form?.setFieldValue(
                                pdfField?.field,
                                e.target.value
                              )
                            }
                          />
                        )}
                      </Box>
                    ) : null
                  )}
                </Box>
              </Box>
              {/*assignee*/}
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt={1}
                width="20%"
                minWidth="fit-content"
              >
                <Box>
                  <Typography
                    fontWeight={550}
                    textTransform="capitalize"
                    letterSpacing="1px"
                    textAlign="center"
                    fontSize="12px"
                    mb={1}
                  >
                    Assignee
                  </Typography>
                  <DropdownComponent
                    id={selectedSubCheck?.id}
                    options={OpsUserBySubRoleIds}
                    optionLabel="assigneeName"
                    optionId="assignee"
                    defaultValue={selectedAssignee[selectedSubCheck?.id]}
                    setDisableBtn={setDisableBtn}
                    setDropdownVal={setSelectedAssignee}
                    toDisableDropdown={
                      !checkActionPermission(
                        permissionKey.opsAssigneUser,
                        permissions
                      )
                    }
                    fullWidth={true}
                    updateDisableStateDirectly={true}
                  />
                </Box>
              </Box>
            </Box>
            {/*check results */}
            <Box mt={1} px={3} width="100%">
              <Box width="80%">
                <Typography
                  fontWeight={550}
                  textTransform="capitalize"
                  letterSpacing="1px"
                  textAlign="left"
                  mb={1}
                  fontSize="12px"
                >
                  Check Results
                </Typography>
                <FastField
                  key={id}
                  multiline
                  minRows={3}
                  component={Textarea}
                  value={form?.values?.checkResult || ""}
                  type="text"
                  size="small"
                  fullWidth
                  InputProps={{
                    sx: {
                      height: "32px",
                    },
                  }}
                  variant="outlined"
                  onChange={(e) =>
                    form?.setFieldValue("checkResult", e.target.value)
                  }
                />
              </Box>
            </Box>
            {/*action log dropdown fields */}
            <Box>
              <ActionLog
                key={selectedSubCheck?.id}
                categoryDropdownFormRef={categoryDropdownFormRef}
                categoryInputsFormRef={categoryInputsFormRef}
                setDisableSubmitBtn={setDisableBtn}
              />
            </Box>
            {/*action log file upload - update btn */}
            <Box px={3} display="flex" width="100%">
              <Box width="80%">
                <ActionLogFileUpload
                  fileUploadFormRef={fileUploadFormRef}
                  setDisableSubmitBtn={setDisableBtn}
                  disableUploadBtn={
                    !categoryDropdownFormRef.current?.values?.categoryIdL1
                  }
                />
              </Box>
              <Box width="20%">
                <Typography
                  fontWeight={550}
                  textTransform="capitalize"
                  letterSpacing="1px"
                  fontSize="12px"
                  textAlign="center"
                >
                  action
                </Typography>
                <Box
                  mt={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexWrap="wrap"
                  gap="5px"
                >
                  {loadingUpdateBtn ? (
                    <Button
                      size="small"
                      variant="contained"
                      disabled={true}
                      color="grey"
                      sx={{ padding: "6px 25px" }}
                    >
                      <CircularLoader height="auto" size={20} />
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      variant="contained"
                      onClick={handleUpdate}
                      disabled={disableBtn}
                      sx={{ boxShadow: "none" }}
                    >
                      Update
                    </Button>
                  )}
                  <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={initializeDropdownValues}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UpdateActionComponent;
