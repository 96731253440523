import { getDynamicFieldLabelName } from "../../utils/sectionFieldsAndLabelsUtils";
import { toDisplayProgramDetailsSubSection } from "./../../education/utils/toDisplayProgramDetailsSubSection";

export const educationHeaders = (sectionInputFields = "") => {
  let allFields = [
    {
      value: "nameOfSchoolCollegeUniversity",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName(
            "nameOfSchoolCollegeUniversity",
            sectionInputFields
          )
        : "Name of School/College/University*",
    },
    {
      value: "educationStreetAddressLine1",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName(
            "educationStreetAddressLine1",
            sectionInputFields
          )
        : "Address Line 1*",
    },
    {
      value: "educationStreetAddressLine2",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName(
            "educationStreetAddressLine2",
            sectionInputFields
          )
        : "Address Line 2",
    },
    {
      value: "educationCityTownDistrict",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName(
            "educationCityTownDistrict",
            sectionInputFields
          )
        : "City/Town/District*",
    },
    {
      value: "educationStateProvince",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("educationStateProvince", sectionInputFields)
        : "State/Province*",
    },
    {
      value: "educationZipPostalAreaCode",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName(
            "educationZipPostalAreaCode",
            sectionInputFields
          )
        : "Zip/Postal/Area Code",
    },
    {
      value: "countryName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("educationCountryId", sectionInputFields)
        : "Country*",
    },
    {
      value: "institutePhoneNumber",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("institutePhoneNumber", sectionInputFields)
        : "Phone number",
    },
    // {
    //   heading: true,
    //   text: "Education {{index}} -Program/Study Details",
    // },
    {
      heading: true,
      text: "Program/Study Details",
    },
    {
      value: "isCurrentlyStudying",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("isCurrentlyStudying", sectionInputFields)
        : "Are you currently studying this qualification?*",
    },
    {
      value: "educationFromDate",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("educationFromDate", sectionInputFields)
        : "From date*",
    },
    {
      value: "educationToDate",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("educationToDate", sectionInputFields)
        : "To date*",
    },
    {
      value: "qualificationTypeName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("qualificationTypeId", sectionInputFields)
        : "Qualification type*",
    },
    {
      value: "qualificationTypeOthers",
      text: "Other Qualification*",
    },
    {
      value: "titleOfQualification",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("titleOfQualification", sectionInputFields)
        : "Title of qualification*",
    },
    {
      value: "educationTypeName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("educationTypeId", sectionInputFields)
        : "Education type*",
    },
    {
      value: "otherEducationType",
      text: "Other Education Type*",
    },
    {
      value: "specilization",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("specilization", sectionInputFields)
        : "Specialization of Your Qualification (major subject)*",
    },
    {
      value: "certificateNo",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("certificateNo", sectionInputFields)
        : "Certificate no*",
    },
    {
      value: "studentEnrollmentNo",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("studentEnrollmentNo", sectionInputFields)
        : "Student No/Enrollment no*",
    },
    {
      value: "gradePercentageGpaScore",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName(
            "gradePercentageGpaScore",
            sectionInputFields
          )
        : "Grade/Percentage/GPA score",
    },
    {
      value: "graduationDate",
      text: "Graduation date",
    },
    {
      value: "educationAdditionalComments",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName(
            "educationAdditionalComments",
            sectionInputFields
          )
        : "Additional comment",
    },
    {
      value: "EDUCATION_DOCS",
      text: "Educations Degree/Marksheet/Any other relevant proofs*",
    },
  ];

  //checking if any of the fields under this section is present or not to display the heading in review
  let isProgramDetailsfieldsPresent = sectionInputFields?.length
    ? toDisplayProgramDetailsSubSection(sectionInputFields)
    : true;

  let removeHeadings = [];

  if (!isProgramDetailsfieldsPresent) {
    removeHeadings.push("Program/Study Details");
  }

  //for candidate section, the 'text' field will be 'undefined' if no field is present in welcome API
  //so we will filter them out to not display an empty row on review section
  let finalFields = allFields?.filter(
    (curr) => curr?.text && !removeHeadings?.includes(curr?.text)
  );

  return finalFields;
};
