const currentSectionInputFieldsData = (
  sectionName,
  candidateProfileSections
) => {
  let finalInputfields = [];
  if (candidateProfileSections) {
    let ifPresent = candidateProfileSections?.find((section) => {
      return section.candidateProfileSectionName === sectionName;
    });

    if (ifPresent) {
      ifPresent?.inputFields?.forEach(
        ({
          candidatesProfileSectionId,
          candidateProfileInputFieldId,
          inputFieldDisplayName,
          inputFieldKey,
          effectiveIsMandatory,
        }) => {
          finalInputfields.push({
            candidatesProfileSectionId,
            sectionName,
            candidateProfileInputFieldId,
            inputFieldDisplayName,
            inputFieldKey,
            isMandatory: Boolean(effectiveIsMandatory),
          });
        }
      );
    }
  }

  return finalInputfields;
};

export default currentSectionInputFieldsData;
