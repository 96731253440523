import * as Yup from "yup";
import { createSectionSchema } from "../../utils/createSectionSchema";
import { identityFieldsSchema } from "./allFieldsSchema";

export const identityValidationSchema = (
  identityDocumentTypeLists,
  inputFields,
  initialValues
) => {
  let mappingSchemaFieldsToDisplayInputFields = {
    identityDocumentType: "identityDocumentType",
    identityDocumentNumber: "identityDocumentType",
    identityAttachments: "identityDocumentType",
  };

  let identitySchema = createSectionSchema(
    initialValues,
    inputFields,
    identityFieldsSchema(identityDocumentTypeLists),
    mappingSchemaFieldsToDisplayInputFields
  );

  return Yup.object().shape(identitySchema);
};
