import { omit } from "lodash";
import { FastField, Form, Formik, setNestedObjectValues } from "formik";
import { useNavigate } from "react-router-dom";
import { BaseAccordion, IOSSwitch, BaseTextField } from "../../base";
import { Box, Card, Grid, Typography } from "@mui/material";
import { useMemo } from "react";
import { Fragment } from "react";
import { StyledBasePaper } from "../../base/styled";
import { newFamilyDetails } from "./helpers/initialValues";
import { familyValidationSchema } from "./helpers/validationSchema";
import { getSectionData } from "../utils/getSectionData";
import { useTheme } from "@mui/material/styles";
import {
  CANDIDATE_CLEAR_API_ERROR,
  CANDIDATE_SECTION_BACK_URL,
  CANDIDATE_SECTION_SUBMIT_HANDLER,
  FAMILY_DETAILS,
} from "../../../store/actions/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  getFamilyDetailsData,
  submitCacheCandidateDetails,
  submitCandidateDetails,
} from "../../../store/actions/candidateAction";
import { useRef } from "react";
import { useEffect } from "react";
import AlertMessageComponent from "../../../common/AlertMessageComponent";
import CircularLoader from "../../../common/CircularLoader";
import DisplaySectionHeading from "../common/DisplaySectionHeading";
import { checkConditionToDisplayExtraFields } from "./../utils/checkConditionToDisplayExtraFields";
import { getCurrentFileNameAndFunction } from "../../../utils/getCurrentFileNameAndFunction";
import currentSectionInputFieldsData from "../utils/currentSectionInputFieldsData";
import {
  checkIfFieldToBeDisplayedOrNot,
  getDynamicFieldLabelName,
} from "../utils/sectionFieldsAndLabelsUtils";
import { createFinalSectionInitialValue } from "../utils/createFinalSectionInitialValue";

const CandidateFamilyPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const toCacheValues = useRef();
  const {
    loading,
    candidateDetails,
    candidateInitialDetails,
    apiErrorMsg,
    candidateCachedDetails,
    familyDetailsData,
    candidateSectionLoading,
  } = useSelector((state) => state.candidate);
  const { candidateProfileSections, allowProfileEdit } =
    candidateInitialDetails;

  const familyDetailsDataRef = useRef();
  familyDetailsDataRef.current = familyDetailsData;

  const familyForm = useRef();

  useEffect(() => {
    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "useEffect"
    );
    dispatch(getFamilyDetailsData(logDetails));
  }, []);

  let inputFields = useMemo(
    () =>
      currentSectionInputFieldsData("FAMILY_DETAILS", candidateProfileSections),
    [candidateProfileSections]
  );

  const familyDetails = useMemo(() => {
    let initialValue = familyDetailsData;
    if (!familyDetailsData) {
      initialValue =
        (candidateCachedDetails && candidateCachedDetails["FAMILY_DETAILS"]) ||
        newFamilyDetails(inputFields);
    }

    //creating final initial values from above calculated one & from the original one
    initialValue = createFinalSectionInitialValue(
      initialValue,
      newFamilyDetails(inputFields)
    );

    return initialValue;
  }, [familyDetailsData, candidateCachedDetails, inputFields]);

  const sectionData = useMemo(() => {
    let data = getSectionData(
      "FAMILY_DETAILS",
      candidateProfileSections,
      allowProfileEdit
    );

    //passing back url for the back btn present in candidate nav section
    dispatch({
      type: CANDIDATE_SECTION_BACK_URL,
      payload: data?.urls?.backUrl,
    });

    return data;
  }, [candidateProfileSections]);

  useEffect(() => {
    //passing handleSubmit and invoking it on next btn which is present in candidate nav section
    dispatch({
      type: CANDIDATE_SECTION_SUBMIT_HANDLER,
      payload: () =>
        handleSubmit(familyForm?.current?.values, familyForm?.current),
    });
    return () => {
      dispatch({ type: CANDIDATE_CLEAR_API_ERROR });
      dispatch({ type: FAMILY_DETAILS, payload: null });

      //caching when dismounts
      if (!familyDetailsDataRef.current && toCacheValues.current) {
        let logDetails = getCurrentFileNameAndFunction(
          import.meta.url,
          "useEffect"
        );

        dispatch(
          submitCacheCandidateDetails(
            logDetails,
            toCacheValues.current,
            "FAMILY_DETAILS"
          )
        );
      }
    };
  }, []);

  const handleSubmit = async (values, form) => {
    try {
      const validationErrors = await form.validateForm();

      //to stop the api call when there are errors , we will simply return
      if (Object.keys(validationErrors)?.length) {
        return form.setTouched(setNestedObjectValues(validationErrors, true));
      }

      dispatch({ type: CANDIDATE_CLEAR_API_ERROR });

      let logDetails = getCurrentFileNameAndFunction(
        import.meta.url,
        "handleSubmit"
      );

      const body = omit(
        values,
        "candidatesFamilyDetailsId",
        "candidateCaseId",
        "deleteCandidateId"
      );

      if (
        checkIfFieldToBeDisplayedOrNot("mothersMaidenNameExists", inputFields)
      ) {
        body.mothersMaidenNameExists = Boolean(body.mothersMaidenNameExists);
      } else {
        delete body.mothersMaidenNameExists;
        delete body.mothersMaidenName;
      }

      dispatch(
        submitCandidateDetails(
          { FAMILY_DETAILS: body },
          "family-details",
          () => {
            toCacheValues.current = {};
            navigate(sectionData?.urls.nextUrl);
          },
          sectionData?.section?.onHold,
          sectionData?.section?.candidatesProfileSectionsId,
          navigate,
          logDetails
        )
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Fragment>
      {!loading && !candidateSectionLoading ? (
        <StyledBasePaper>
          <Formik
            enableReinitialize
            initialValues={familyDetails}
            validationSchema={() =>
              familyValidationSchema(inputFields, familyDetails)
            }
            onSubmit={handleSubmit}
            innerRef={familyForm}
          >
            {(form) => {
              toCacheValues.current = form?.values;
              return (
                <>
                  <Box mb={2}>
                    {!familyDetailsData ? (
                      <AlertMessageComponent
                        cond={() => {
                          return !familyDetailsData;
                        }}
                        CONSTANT="FAMILY_DETAILS"
                        toCacheValues={toCacheValues.current}
                        sectionDetails={familyDetailsData}
                      />
                    ) : null}
                  </Box>
                  <DisplaySectionHeading
                    icon={sectionData?.section?.sectionIcon}
                    text={sectionData?.section?.candidateRequiredInfoText}
                  />

                  <Form>
                    <Card variant="outlined" sx={{ borderRadius: "12px" }}>
                      <Box p={{ xs: 2, md: 4 }}>
                        <Grid container spacing={{ xs: 1, md: 4 }}>
                          {checkIfFieldToBeDisplayedOrNot(
                            "fathersFirstName",
                            inputFields
                          ) && (
                            <Grid item xs={12} sm={6} md={4}>
                              <FastField
                                component={BaseTextField}
                                name="fathersFirstName"
                                label={getDynamicFieldLabelName(
                                  "fathersFirstName",
                                  inputFields
                                )}
                              />
                            </Grid>
                          )}
                          {checkIfFieldToBeDisplayedOrNot(
                            "fathersMiddleName",
                            inputFields
                          ) && (
                            <Grid item xs={12} sm={6} md={4}>
                              <FastField
                                component={BaseTextField}
                                name="fathersMiddleName"
                                label={getDynamicFieldLabelName(
                                  "fathersMiddleName",
                                  inputFields
                                )}
                              />
                            </Grid>
                          )}
                          {checkIfFieldToBeDisplayedOrNot(
                            "fathersLastName",
                            inputFields
                          ) && (
                            <Grid item xs={12} sm={6} md={4}>
                              <FastField
                                component={BaseTextField}
                                name="fathersLastName"
                                label={getDynamicFieldLabelName(
                                  "fathersLastName",
                                  inputFields
                                )}
                              />
                            </Grid>
                          )}
                          {checkIfFieldToBeDisplayedOrNot(
                            "mothersFirstName",
                            inputFields
                          ) && (
                            <Grid item xs={12} sm={6} md={4}>
                              <FastField
                                component={BaseTextField}
                                name="mothersFirstName"
                                label={getDynamicFieldLabelName(
                                  "mothersFirstName",
                                  inputFields
                                )}
                              />
                            </Grid>
                          )}
                          {checkIfFieldToBeDisplayedOrNot(
                            "mothersMiddleName",
                            inputFields
                          ) && (
                            <Grid item xs={12} sm={6} md={4}>
                              <FastField
                                component={BaseTextField}
                                name="mothersMiddleName"
                                label={getDynamicFieldLabelName(
                                  "mothersMiddleName",
                                  inputFields
                                )}
                              />
                            </Grid>
                          )}
                          {checkIfFieldToBeDisplayedOrNot(
                            "mothersLastName",
                            inputFields
                          ) && (
                            <Grid item xs={12} sm={6} md={4}>
                              <FastField
                                component={BaseTextField}
                                name="mothersLastName"
                                label={getDynamicFieldLabelName(
                                  "mothersLastName",
                                  inputFields
                                )}
                              />
                            </Grid>
                          )}
                          {checkIfFieldToBeDisplayedOrNot(
                            "spouseFirstName",
                            inputFields
                          ) && (
                            <Grid item xs={12} sm={6} md={4}>
                              <FastField
                                component={BaseTextField}
                                name="spouseFirstName"
                                label={getDynamicFieldLabelName(
                                  "spouseFirstName",
                                  inputFields
                                )}
                              />
                            </Grid>
                          )}{" "}
                          {checkIfFieldToBeDisplayedOrNot(
                            "spouseMiddleName",
                            inputFields
                          ) && (
                            <Grid item xs={12} sm={6} md={4}>
                              <FastField
                                component={BaseTextField}
                                name="spouseMiddleName"
                                label={getDynamicFieldLabelName(
                                  "spouseMiddleName",
                                  inputFields
                                )}
                              />
                            </Grid>
                          )}
                          {checkIfFieldToBeDisplayedOrNot(
                            "spouseLastName",
                            inputFields
                          ) && (
                            <Grid item xs={12} sm={6} md={4}>
                              <FastField
                                component={BaseTextField}
                                name="spouseLastName"
                                label={getDynamicFieldLabelName(
                                  "spouseLastName",
                                  inputFields
                                )}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Card>

                    {checkIfFieldToBeDisplayedOrNot(
                      "mothersMaidenNameExists",
                      inputFields
                    ) && (
                      <Box mt={{ xs: 2, md: 6 }}>
                        <BaseAccordion
                          expandIcon={false}
                          headerbg="#F2F5FE"
                          bordercolor={theme.palette.grey[400]}
                          expanded={form.values.mothersMaidenNameExists ? 1 : 0}
                          onChange={(e) =>
                            form.setFieldValue("mothersMaidenNameExists", e)
                          }
                          header={(header) => (
                            <Box
                              width={"100%"}
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              xs={{ fontSize: "12px" }}
                            >
                              <span id="family-did-your-mother-text">
                                {getDynamicFieldLabelName(
                                  "mothersMaidenNameExists",
                                  inputFields
                                )}{" "}
                              </span>
                              <IOSSwitch checked={header.expanded} />
                            </Box>
                          )}
                        >
                          <FastField
                            component={BaseTextField}
                            name="mothersMaidenName"
                            label="Mother's Maiden Name*"
                          />
                        </BaseAccordion>
                      </Box>
                    )}
                    {apiErrorMsg && (
                      <Typography
                        mt={2}
                        color="error"
                        style={{ textTransform: "capitalize" }}
                      >
                        {apiErrorMsg}*
                      </Typography>
                    )}
                  </Form>
                </>
              );
            }}
          </Formik>
        </StyledBasePaper>
      ) : (
        <CircularLoader />
      )}
    </Fragment>
  );
};

export { CandidateFamilyPage };
