import { toDisplayAddressDetailsSubSection } from "../../professional/utils/toDisplayAddressDetailsSubSection";
import { getDynamicFieldLabelName } from "../../utils/sectionFieldsAndLabelsUtils";

export const qualificationHeaders = (sectionInputFields = "") => {
  let allFields = [
    {
      value: "professionalQualificationTitle",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName(
            "professionalQualificationTitle",
            sectionInputFields
          )
        : "Professional qualification title*",
    },
    {
      value: "qualifyingInstituteBodyName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName(
            "qualifyingInstituteBodyName",
            sectionInputFields
          )
        : "Qualifying Institute/body name*",
    },
    {
      value: "statusProfessionalQualificationName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName(
            "statusProfessionalQualificationId",
            sectionInputFields
          )
        : "Status of Your Professional Qualification*",
    },
    {
      value: "otherProffesionalQualificationStatus",
      text: "Other professional qualification status*",
    },
    {
      value: "membershipQualificationNumber",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName(
            "membershipQualificationNumber",
            sectionInputFields
          )
        : "Membership/Qualification number",
    },
    {
      value: "professionalFromDate",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("professionalFromDate", sectionInputFields)
        : "From date*",
    },
    {
      value: "professionalToDate",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("professionalToDate", sectionInputFields)
        : "To date*",
    },
    {
      value: "dateOfAccreditation",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("dateOfAccreditation", sectionInputFields)
        : "Date of accreditation*",
    },
    {
      value: "professionalAdditionalComments",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName(
            "professionalAdditionalComments",
            sectionInputFields
          )
        : "Additional comment",
    },
    // {
    //   heading: true,
    //   text: "Professional Qualification {{index}} - Address",
    // },
    {
      heading: true,
      text: "Address",
    },
    {
      value: "professionalStreetAddressLine1",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName(
            "professionalStreetAddressLine1",
            sectionInputFields
          )
        : "Address Line 1*",
    },
    {
      value: "professionalStreetAddressLine2",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName(
            "professionalStreetAddressLine2",
            sectionInputFields
          )
        : "Address Line 2",
    },
    {
      value: "professionalCityTownDistrict",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName(
            "professionalCityTownDistrict",
            sectionInputFields
          )
        : "City/Town/District*",
    },
    {
      value: "professionalStateProvince",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName(
            "professionalStateProvince",
            sectionInputFields
          )
        : "State/Province*",
    },
    {
      value: "countryName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("professionalCountryId", sectionInputFields)
        : "Country*",
    },
    {
      value: "professionalZipPostalAreaCode",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName(
            "professionalZipPostalAreaCode",
            sectionInputFields
          )
        : "Zip/Postal/Area Code",
    },
    {
      value: "institutePhoneNumber",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("institutePhoneNumber", sectionInputFields)
        : "Phone number",
    },
    {
      value: "PROFESSIONAL_DOCS",
      text: "Professional qualification license for verification*",
    },
  ];

  //checking if any of the fields under this section is present or not to display the heading in review
  let isAddressDetailsfieldsPresent = sectionInputFields?.length
    ? toDisplayAddressDetailsSubSection(sectionInputFields)
    : true;

  let removeHeadings = [];

  if (!isAddressDetailsfieldsPresent) {
    removeHeadings.push("Address");
  }

  //for candidate section, the 'text' field will be 'undefined' if no field is present in welcome API
  //so we will filter them out to not display an empty row on review section
  let finalFields = allFields?.filter(
    (curr) => curr?.text && !removeHeadings?.includes(curr?.text)
  );

  return finalFields;
};
