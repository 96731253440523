import { Box, Grid } from "@mui/material";
import SingleInputFieldSettings from "../components/SingleInputFieldSettings";

const AddressSkeleton = () => {
  return (
    <Box mb={1} maxWidth={1000} margin="0 auto">
      <Grid container spacing={1} mb={1}>
        <Grid item xs={12} md={6}>
          <SingleInputFieldSettings
            name="streetAddressLine1"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SingleInputFieldSettings
            name="streetAddressLine2"
            orientation="horizontal"
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} mb={1}>
        <Grid item xs={12} md={4}>
          <SingleInputFieldSettings
            name="cityTownDistrict"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <SingleInputFieldSettings
            name="stateProvince"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <SingleInputFieldSettings
            name="addressCountryId"
            orientation="horizontal"
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <SingleInputFieldSettings
            name="zipPostalAreaCode"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <SingleInputFieldSettings
            name="addressFromDate"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <SingleInputFieldSettings
            name="addressToDate"
            orientation="horizontal"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddressSkeleton;
