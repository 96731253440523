import { Box, Divider, Grid, Typography } from "@mui/material";
import SingleInputFieldSettings from "../components/SingleInputFieldSettings";

const EducationSkeleton = () => {
  return (
    <Box maxWidth={1000} margin="0 auto">
      <Grid container xs={12} spacing={1}>
        <Grid item xs={12} md={6}>
          <SingleInputFieldSettings
            name="educationStreetAddressLine1"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SingleInputFieldSettings
            name="educationStreetAddressLine2"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SingleInputFieldSettings
            name="nameOfSchoolCollegeUniversity"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <SingleInputFieldSettings name="educationCityTownDistrict" />
        </Grid>

        <Grid item xs={12} md={3}>
          <SingleInputFieldSettings name="educationStateProvince" />
        </Grid>

        <Grid item xs={12} md={4}>
          <SingleInputFieldSettings
            name="educationZipPostalAreaCode"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <SingleInputFieldSettings
            name="educationCountryId"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <SingleInputFieldSettings
            name="institutePhoneNumber"
            orientation="horizontal"
          />
        </Grid>
      </Grid>

      <Divider sx={{ margin: "10px" }} />

      <Grid container xs={12} spacing={1}>
        <Grid item xs={12} md={6} display="flex" flexDirection="column" gap={1}>
          <Grid item xs={12}>
            <SingleInputFieldSettings
              name="qualificationTypeId"
              orientation="horizontal"
            />
          </Grid>

          <Grid item xs={12}>
            <SingleInputFieldSettings
              name="titleOfQualification"
              orientation="horizontal"
            />
          </Grid>

          <Grid item xs={12}>
            <SingleInputFieldSettings
              name="specilization"
              orientation="horizontal"
            />
          </Grid>

          <Grid item xs={12}>
            <SingleInputFieldSettings
              name="educationTypeId"
              orientation="horizontal"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} display="flex" flexDirection="column" gap={1}>
          <Grid item xs={12}>
            <SingleInputFieldSettings
              name="isCurrentlyStudying"
              orientation="horizontal"
            />
          </Grid>

          <Grid container xs={12} display="flex" gap={1}>
            <Grid item xs={5.8}>
              <SingleInputFieldSettings name="educationFromDate" />
            </Grid>

            <Grid item xs={5.8}>
              <SingleInputFieldSettings name="educationToDate" />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <SingleInputFieldSettings
              name="gradePercentageGpaScore"
              orientation="horizontal"
            />
          </Grid>

          <Grid item xs={12}>
            <SingleInputFieldSettings
              name="certificateNo"
              orientation="horizontal"
            />
          </Grid>

          <Grid item xs={12}>
            <SingleInputFieldSettings
              name="studentEnrollmentNo"
              orientation="horizontal"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} mt={1}>
        <SingleInputFieldSettings
          name="educationAdditionalComments"
          orientation="horizontal"
        />
      </Grid>

      <Divider sx={{ margin: "10px" }} />

      <Box mb={1}>
        <SingleInputFieldSettings
          name="attachmentsDegree"
          orientation="horizontal"
        />
      </Box>
    </Box>
  );
};

export default EducationSkeleton;
