import {
  commonDropdownSchema,
  commonEmailSchema,
  commonPhoneSchema,
  commonStringSchema,
} from "../../utils/commonFieldSchema";

export const professionalReferenceFieldsSchema = {
  fullName: (isMandatory) =>
    commonStringSchema(isMandatory, "Full Name is required"),
  title: (isMandatory) => commonStringSchema(isMandatory, "Title is required"),
  referanceCompanyName: (isMandatory) =>
    commonStringSchema(isMandatory, "Company Name is required"),
  professionalRelationshipId: (isMandatory) =>
    commonDropdownSchema(isMandatory, "Professional Relationship is required"),
  city: (isMandatory) => commonStringSchema(isMandatory, "City is required"),
  referanceCountryId: (isMandatory) =>
    commonDropdownSchema(isMandatory, "Country is required"),
  email: (isMandatory) => commonEmailSchema(isMandatory, "Email is required"),
  referencePhoneNumber: (isMandatory) => commonPhoneSchema(isMandatory),
  referencePhoneCountryCode: (isMandatory) =>
    commonStringSchema(isMandatory, "Country code is required"),
  referenceAdditionalComments: (isMandatory) =>
    commonStringSchema(isMandatory, "Additional Comment is required"),
};
