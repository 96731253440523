import { getDynamicFieldLabelName } from "../../utils/sectionFieldsAndLabelsUtils";

export const employmentGapHeaders = (sectionInputFields = "") => {
  let allFields = [
    {
      value: "gapStartDate",
      text: "Gap start date*",
    },
    {
      value: "gapEndDate",
      text: "Gap end date*",
    },
    {
      value: "reasonOfGapName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("reasonOfGapId", sectionInputFields)
        : "Reason of gap*",
    },
    {
      value: "employmentAdditionalComments",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName(
            "employmentAdditionalComments",
            sectionInputFields
          )
        : "Additional comments",
    },
  ];

  //for candidate section, the 'text' field will be 'undefined' if no field is present in welcome API
  //so we will filter them out to not display an empty row on review section
  let finalFields = allFields?.filter((curr) => curr?.text);

  return finalFields;
};
