import { getDynamicFieldLabelName } from "../../utils/sectionFieldsAndLabelsUtils";

export const familyHeaders = (sectionInputFields = "") => {
  let allFields = [
    {
      value: "fathersFirstName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("fathersFirstName", sectionInputFields)
        : "Father's first name",
    },
    {
      value: "fathersMiddleName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("fathersMiddleName", sectionInputFields)
        : "Father's middle name",
    },
    {
      value: "fathersLastName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("fathersLastName", sectionInputFields)
        : "Father's last name",
    },
    {
      value: "mothersFirstName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("mothersFirstName", sectionInputFields)
        : "Mother's first name",
    },
    {
      value: "mothersMiddleName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("mothersMiddleName", sectionInputFields)
        : "Mother's middle name",
    },
    {
      value: "mothersLastName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("mothersLastName", sectionInputFields)
        : "Mother's last name",
    },
    {
      value: "spouseFirstName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("spouseFirstName", sectionInputFields)
        : "Spouse's first name*",
    },
    {
      value: "spouseMiddleName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("spouseMiddleName", sectionInputFields)
        : "Spouse's middle name*",
    },
    {
      value: "spouseLastName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("spouseLastName", sectionInputFields)
        : "Spouse's last name*",
    },
    {
      value: "mothersMaidenNameExists",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName(
            "mothersMaidenNameExists",
            sectionInputFields,
            false
          )
        : "Does your mother have any maiden name?",
    },
    {
      value: "mothersMaidenName",
      text: "Mother's maiden name",
      show(v) {
        return v.mothersMaidenNameExists && v.mothersMiddleName;
      },
    },
  ];

  //for candidate section, the 'text' field will be 'undefined' if no field is present in welcome API
  //so we will filter them out to not display an empty row on review section
  let finalFields = allFields?.filter((curr) => curr?.text);

  return finalFields;
};
