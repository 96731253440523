import { Box } from "@mui/material";
import { memo, useMemo } from "react";
import PersonalDetailsSkeleton from "../SectionSkeletions/PersonalDetailsSkeleton";
import { isEqual } from "lodash";
import AddressSkeleton from "../SectionSkeletions/AddressSkeleton";
import FamilySkeleton from "../SectionSkeletions/FamilySkeleton";
import EmploymentSkeletion from "../SectionSkeletions/EmploymentSkeletion";
import EducationSkeleton from "../SectionSkeletions/EducationSkeleton";
import QualificationSkeleton from "../SectionSkeletions/QualificationSkeleton";
import ReferenceSkeleton from "../SectionSkeletions/ReferenceSkeleton";
import IdentitySkeleton from "../SectionSkeletions/IdentitySkeleton";

const SelectedSectionFieldsSettings = memo(
  ({ candidatesProfileSectionId }) => {
    let CurrentSection = useMemo(() => {
      switch (candidatesProfileSectionId) {
        case 1:
          return <PersonalDetailsSkeleton />;
        case 2:
          return <AddressSkeleton />;
        case 3:
          return <FamilySkeleton />;
        case 4:
          return <EmploymentSkeletion />;
        case 5:
          return <EducationSkeleton />;
        case 6:
          return <QualificationSkeleton />;
        case 7:
          return <ReferenceSkeleton />;
        case 8:
          return <IdentitySkeleton />;
        default:
          <></>;
      }
    }, [candidatesProfileSectionId]);

    return <Box p={1}>{CurrentSection}</Box>;
  },
  (prev, next) => !isEqual(prev, next)
);

export default SelectedSectionFieldsSettings;
