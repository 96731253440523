import { createSectionInitialDetailsFields } from "../../utils/createSectionInitialDetailsFields";

export const newEducation = (inputFields) => {
  let allFields = {
    nameOfSchoolCollegeUniversity: "",
    educationStreetAddressLine1: "",
    educationStreetAddressLine2: "",
    educationCityTownDistrict: "",
    educationStateProvince: "",
    educationZipPostalAreaCode: "",
    educationCountryId: "",
    institutePhoneNumber: {
      institutePhoneCountryCode: null,
      institutePhoneNumber: "",
    },
    isCurrentlyStudying: { isCurrentlyStudying: true, graduationDate: null },
    educationFromDate: "",
    educationToDate: "",
    titleOfQualification: "",
    specilization: "",
    qualificationTypeId: {
      qualificationTypeId: null,
      qualificationTypeOthers: "",
    },
    educationTypeId: { educationTypeId: null, otherEducationType: "" },
    certificateNo: "",
    studentEnrollmentNo: "",
    gradePercentageGpaScore: "",
    educationAdditionalComments: "",
  };

  return {
    ...createSectionInitialDetailsFields(inputFields, allFields),
    candidatesEducationsId: "",
    attachments: [],
    selectedTab: true,
    isExpanded: true,
  };
};
