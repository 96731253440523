import { Box, Grid } from "@mui/material";
import SingleInputFieldSettings from "../components/SingleInputFieldSettings";

const FamilySkeleton = () => {
  return (
    <Box maxWidth={1000} margin="0 auto">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4}>
          <SingleInputFieldSettings
            name="fathersFirstName"
            orientation="horizontal"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SingleInputFieldSettings
            name="fathersMiddleName"
            orientation="horizontal"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SingleInputFieldSettings
            name="fathersLastName"
            orientation="horizontal"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SingleInputFieldSettings
            name="mothersFirstName"
            orientation="horizontal"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SingleInputFieldSettings
            name="mothersMiddleName"
            orientation="horizontal"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SingleInputFieldSettings
            name="mothersLastName"
            orientation="horizontal"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SingleInputFieldSettings
            name="spouseFirstName"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <SingleInputFieldSettings
            name="spouseMiddleName"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <SingleInputFieldSettings
            name="spouseLastName"
            orientation="horizontal"
          />
        </Grid>
      </Grid>

      <Box mt={2}>
        <SingleInputFieldSettings
          name="mothersMaidenNameExists"
          orientation="horizontal"
        />
      </Box>
    </Box>
  );
};

export default FamilySkeleton;
