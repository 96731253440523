import { checkIfFieldToBeDisplayedOrNot } from "../../utils/sectionFieldsAndLabelsUtils";

export const toDisplaySalaryDetailsSubSection = (inputFields) => {
  const fields = [
    "salaryFrequencyId",
    "salaryCurrencyId",
    "salaryAmount",
    "otherRemuneration",
    "wasBonusReceived",
  ];

  for (let field of fields) {
    if (checkIfFieldToBeDisplayedOrNot(field, inputFields)) {
      return true;
    }
  }
  return false;
};
