export const createSectionSchema = (
  initialValues,
  inputFields,
  sectionSchema,
  mappingSchemaFieldsToDisplayInputFields = {}
) => {
  let finalSchema = {};
  for (let finalfield in initialValues) {
    let present = inputFields?.find((field) => {
      if (
        field.inputFieldKey ===
          mappingSchemaFieldsToDisplayInputFields[finalfield] ||
        field.inputFieldKey === finalfield
      ) {
        return field;
      }
    });

    if (present) {
      finalSchema = {
        ...finalSchema,
        [finalfield]:
          sectionSchema[finalfield] &&
          sectionSchema[finalfield](present?.isMandatory),
      };
    }
  }

  return finalSchema;
};
