import * as Yup from "yup";
import { customValidationForDate } from "./customValidationForDate";

//string schema
export const commonStringSchema = (isMandatory, errorMessage, charLimit = 50) =>
  Yup.string()
    .nullable()
    .max(charLimit, `Should not exceed ${charLimit} characters`)
    .when([], {
      is: () => isMandatory,
      then: (schema) => schema.required(errorMessage),
    });

// date schema
export const commonDateSchema = (
  isMandatory,
  errorMessage = "From Date is required"
) =>
  Yup.string()
    .nullable()
    .when([], {
      is: () => isMandatory,
      then: (schema) => schema.required(errorMessage).typeError(errorMessage),
    })
    .test({
      name: "customValidationForDate",
      test: customValidationForDate,
      message: "Invalid date format",
      exclusive: true,
    });

//to date schema
export const commonToDateSchema = (isMandatory, fromDateKeyName = "fromDate") =>
  Yup.date()
    .nullable()
    .required("To Date is required")
    .typeError("To Date is required")
    .when(`${fromDateKeyName}`, (fromDateValue, schema) => {
      if (!fromDateValue || !isMandatory) return;

      return schema.min(
        fromDateValue,
        "To date can't be smaller than From date"
      );
    })
    .test({
      name: "customValidationForDate",
      test: customValidationForDate,
      message: "Invalid date format",
      exclusive: true,
    });

// dropdown schema
export const commonDropdownSchema = (isMandatory, errorMessage) =>
  Yup.number()
    .nullable()
    .when([], {
      is: () => isMandatory,
      then: (schema) => schema.required(errorMessage).typeError(errorMessage),
    });

// email schema
export const commonEmailSchema = (isMandatory, errorMessage) =>
  Yup.string()
    .nullable()
    .max(50, "Should not exceed 50 characters")
    .email("Please provide a valid email address")
    .when([], {
      is: () => isMandatory,
      then: (schema) => schema.required(errorMessage),
    });

//phone schema
export const commonPhoneSchema = (isMandatory) =>
  Yup.string()
    .nullable()
    .when([], {
      is: () => isMandatory,
      then: (schema) =>
        schema
          .required("A phone number is required")
          .phone("*", "Please enter a valid phone number"),
    });

//state province schema
export const commonStateProvinceSchema = (isMandatory, countryFieldName) =>
  Yup.string()
    .nullable()
    .max(100, "Should not exceed 100 characters")
    .when(`${countryFieldName}`, {
      is: (countryFieldName) => {
        if (countryFieldName === 101 || countryFieldName === 132) {
          return false;
        }
        return true; //will not depend on the mandatory value coming from welcome api
      },
      then: (schema) => schema.required("State/Province is required"),
    });
