import { Box, Divider, Grid, Typography } from "@mui/material";
import SingleInputFieldSettings from "../components/SingleInputFieldSettings";

const IdentitySkeleton = () => {
  return (
    <Box maxWidth={1000} margin="0 auto">
      <Box>
        <SingleInputFieldSettings
          name="primaryNationalityId"
          orientation="horizontal"
        />
      </Box>

      <Box mt={1}>
        <SingleInputFieldSettings
          name="residenceCountryId"
          orientation="horizontal"
        />
      </Box>

      <Box mt={1}>
        <SingleInputFieldSettings
          name="birthCountryId"
          orientation="horizontal"
        />
      </Box>

      <Box mt={1}>
        <SingleInputFieldSettings
          name="identityDocumentType"
          orientation="horizontal"
        />
      </Box>
    </Box>
  );
};

export default IdentitySkeleton;
