export const supportingDocsHeaders = () => {
  return [
    {
      value: "uploadedByCandidate",
      text: "Uploaded By Candidate",
    },
    {
      value: "uploadedByOpsUser",
      text: "Uploaded By Ops User",
    },
    {
      value: "uplodedByHr",
      text: "Uploaded By HR",
    },
  ];
};
