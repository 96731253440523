import { Alert, Box, Typography } from "@mui/material";
import { isEqual } from "lodash";
import { useMemo } from "react";

const DisplayErrorsForMultipleFields = ({
  setSectionIndexContainingErrors = () => {},
  errors,
  touched,
  section = "Address",
  apiErrorMsg,
}) => {
  let errorValues = useMemo(() => {
    let finalErrorValues = [];

    errors?.map((errorValue, ind) => {
      if (errorValue && touched) {
        let errorFields = [];

        for (let errorObj in errorValue) {
          if (touched[ind] && touched[ind][errorObj]) {
            //bonus in employment is an array so we have to extract error texts from bonus array
            if (Array.isArray(errors[ind][errorObj])) {
              errors[ind][errorObj]?.forEach((arrErrorValue, index) => {
                for (let arrErrorObj in arrErrorValue) {
                  errorFields.push(
                    `${index + 1}) ${arrErrorValue[arrErrorObj]}`
                  );
                }
              });
            } else {
              errorFields.push(errors[ind][errorObj]);
            }
          }
        }

        finalErrorValues.push({ id: ind, errorFields });
      }
    });

    setSectionIndexContainingErrors((prev) => {
      let newVal = finalErrorValues?.map(({ id }) => id);
      return isEqual(prev, newVal) ? prev : newVal;
    });

    return finalErrorValues;
  }, [errors, touched, section]);

  return (
    <>
      {errorValues?.map((errorValue, ind) =>
        errorValue?.errorFields?.length ? (
          <Box key={ind} mt={2}>
            <Alert severity="error">
              <strong>
                For {section} {errorValue?.id + 1} :&nbsp;
              </strong>
              {errorValue?.errorFields?.join(" , ")}
              {"."}
            </Alert>
          </Box>
        ) : null
      )}

      {apiErrorMsg && (
        <Typography
          mt={2}
          color="error"
          style={{ textTransform: "capitalize" }}
        >
          {apiErrorMsg}*
        </Typography>
      )}
    </>
  );
};

export default DisplayErrorsForMultipleFields;
