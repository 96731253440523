import * as Yup from "yup";
import {
  commonDateSchema,
  commonDropdownSchema,
  commonStateProvinceSchema,
  commonStringSchema,
  commonToDateSchema,
} from "../../utils/commonFieldSchema";

export const addressFieldsSchema = {
  streetAddressLine1: (isMandatory) =>
    commonStringSchema(isMandatory, "Address 1 is required", 100),
  streetAddressLine2: (isMandatory) =>
    commonStringSchema(isMandatory, "Address 2 is required", 100),
  cityTownDistrict: (isMandatory) =>
    commonStringSchema(isMandatory, "City/Town/District is required", 100),
  stateProvince: (isMandatory) => commonStateProvinceSchema(isMandatory, "addressCountryId"),

  addressCountryId: (isMandatory) =>
    commonDropdownSchema(isMandatory, "Country is required"),
  zipPostalAreaCode: (isMandatory) =>
    commonStringSchema(isMandatory, "Zip/Postal/Area Code is required", 100),
  addressFromDate: (isMandatory) => commonDateSchema(isMandatory),
  addressToDate: (isMandatory) =>
    commonToDateSchema(isMandatory, "addressFromDate"),
};
