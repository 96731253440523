import { checkIfFieldToBeDisplayedOrNot } from "../../utils/sectionFieldsAndLabelsUtils";

export const toDisplayProgramDetailsSubSection = (inputFields) => {
  let fields = [
    "qualificationTypeId",
    "titleOfQualification",
    "specilization",
    "educationTypeId",
    "isCurrentlyStudying",
    "educationFromDate",
    "educationToDate",
    "gradePercentageGpaScore",
    "certificateNo",
    "studentEnrollmentNo",
    "educationAdditionalComments",
  ];

  for (let field of fields) {
    if (checkIfFieldToBeDisplayedOrNot(field, inputFields)) {
      return true;
    }
  }
  return false;
};
