import { checkIfFieldToBeDisplayedOrNot } from "../../utils/sectionFieldsAndLabelsUtils";

export const toDisplayCompanyDetailsSubSection = (inputFields) => {
  const fields = [
    "employerPhoneNumber",
    "employerZipPostalAreaCode",
    "employerCountryId",
    "employerStateProvince",
    "employerCityTownDistrict",
    "employerStreetAddressLine2",
    "employerStreetAddressLine1",
  ];

  for (let field of fields) {
    if (checkIfFieldToBeDisplayedOrNot(field, inputFields)) {
      return true;
    }
  }
  return false;
};
