import { useSelector } from "react-redux";
import * as Comlink from "comlink";
import { useAsyncCallback } from "react-async-hook";
import Worker from "worker-loader!./webWorker/web.worker.js";

import CircularJSON from "circular-json";
import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
import { convertPdfUrlToImg } from "./utils/convertPdfUrlToImg";
import { cloneDeep } from "lodash";


const ExportReport = () => {
  const {
    OpsBasicCandidateInfo,
    OpsCandidateCaseChecksList,
    allSubChecksLists,
    actionLogAttachmentsBySubcheck,
    groupedSubCheckDataBasedOnCheckType,
  } = useSelector((state) => state.operations);
  const { candidateDetailsById, candidateDetailsByIdLoading } = useSelector(
    (state) => state.hr
  );

  //hiding report if any check is onHold
  let isAnyCheckOnHold = useMemo(() => {
    return OpsCandidateCaseChecksList?.find((curr) => curr?.onHold);
  }, [OpsCandidateCaseChecksList]);

  const exportAction = useExportCallback({
    OpsBasicCandidateInfo,
    OpsCandidateCaseChecksList,
    allSubChecksLists,
    candidateDetailsById,
    actionLogAttachmentsBySubcheck,
    groupedSubCheckDataBasedOnCheckType,
  });

  console.log("00000-reportpdf", exportAction);

  return (
    <Box component="span" display={isAnyCheckOnHold ? "none" : "flex"}>
      {candidateDetailsByIdLoading ? (
        <Typography
          fontWeight={550}
          component="a"
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
        >
          [Loading...]
        </Typography>
      ) : exportAction?.status === "not-requested" ? (
        <Typography
          fontWeight={550}
          component="a"
          sx={{
            textDecoration: "underline",
            cursor: "pointer",
            color: (theme) => theme.palette.primary.main,
          }}
          onClick={exportAction.execute}
        >
          [Click here to generate a report]
        </Typography>
      ) : exportAction?.status === "success" && exportAction.result ? (
        <>
          <Typography
            fontWeight={550}
            component="a"
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
              color: (theme) => theme.palette.primary.main,
            }}
            onClick={exportAction.execute}
          >
            [Click here to again generate a report]
          </Typography>
          &nbsp;
          <Typography
            fontWeight={550}
            component="a"
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
              color: (theme) => theme.palette.primary.main,
            }}
            href={exportAction.result}
            target="_blank"
          >
            [View report]
          </Typography>
        </>
      ) : exportAction?.status == "error" ? (
        <Typography
          component="a"
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
          fontWeight={550}
        >
          [An error occurred while generating report...]
        </Typography>
      ) : exportAction?.status === "loading" ? (
        <Typography
          component="a"
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
          fontWeight={550}
        >
          [Generating report (Please wait for few mins)...]
        </Typography>
      ) : null}
    </Box>
  );
};

const useExportCallback = (props) => {

  //converting pdf to array of images
  let updatedAttachments = useMemo(async () => {
    let updatedAttachments = {};

    for (let attachment in cloneDeep(props?.actionLogAttachmentsBySubcheck)) {
      let subCheckAttachments = [];

      for (let subCheckAttachment of cloneDeep(
        props?.actionLogAttachmentsBySubcheck[attachment]
      )) {
        if (subCheckAttachment?.attachmentPath?.includes(".pdf")) {
          subCheckAttachment.attachmentPath = await convertPdfUrlToImg(
            subCheckAttachment?.attachmentPath
          );
        }

        subCheckAttachments.push(subCheckAttachment);
      }

      updatedAttachments = {
        ...updatedAttachments,
        [attachment]: subCheckAttachments,
      };
    }

    return updatedAttachments;
  }, [props?.actionLogAttachmentsBySubcheck]);

  let workerRes = useAsyncCallback(async () => {
    const worker = new Worker();
    const pdfWorker = Comlink.wrap(worker);
    pdfWorker.onProgress(
      Comlink.proxy((info) => {
        // console.log(info);
      })
    );

    //initializinf with array of pdf imgs
    let attachments = await updatedAttachments;

    let serializedProps = {
      OpsBasicCandidateInfo: CircularJSON.stringify(
        props?.OpsBasicCandidateInfo
      ),
      OpsCandidateCaseChecksList: props.OpsCandidateCaseChecksList,
      allSubChecksLists: props.allSubChecksLists,
      candidateDetailsById: CircularJSON.stringify(props?.candidateDetailsById),
      actionLogAttachmentsBySubcheck: CircularJSON.stringify(attachments),
      groupedSubCheckDataBasedOnCheckType: CircularJSON.stringify(
        props?.groupedSubCheckDataBasedOnCheckType
      ),
    };

    serializedProps = CircularJSON.stringify(serializedProps);

    const pdfBlob = await pdfWorker.generateAll(serializedProps);
    return URL.createObjectURL(pdfBlob);
  }, [props, updatedAttachments]);

  return workerRes;
};

export default ExportReport;
