import * as Yup from "yup";
import "yup-phone-lite";
import { createSectionSchema } from "../../utils/createSectionSchema";
import { personalParticularFieldsSchema } from "./allFieldsSchema";

export const personalPerticularsValidationSchema = (
  inputFields,
  initialValues
) => {
  //mapping initialValues field name to that of inputFields field name so that their schema can be included. 
  let mappingSchemaFieldsToDisplayInputFields = {
    mobileNumberCountryCode: "mobileNumber",
    otherNames: "otherNameExists",
    formerNames: "formerNameExists",
    nickName: "nickNameExists",
    DINNumber: "DINNumberExists",
    driverLicenseNumber: "driverLicenseNumberExists",
    driverLicenseFrontCopy: "driverLicenseNumberExists",
    driverLicenseBackCopy: "driverLicenseNumberExists",
  };

  let finalSchema = createSectionSchema(
    initialValues,
    inputFields,
    personalParticularFieldsSchema,
    mappingSchemaFieldsToDisplayInputFields,
  );

  return Yup.object().shape(finalSchema);
};
