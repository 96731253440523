import { checkIfFieldToBeDisplayedOrNot } from "../../utils/sectionFieldsAndLabelsUtils";

export const toDisplayHrDetailsSubSection = (inputFields) => {
  const fields = ["hrName", "hrTitle", "hrEmail", "hrPhoneNumber"];

  for (let field of fields) {
    if (checkIfFieldToBeDisplayedOrNot(field, inputFields)) {
      return true;
    }
  }
  return false;
};
