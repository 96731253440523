import { getDynamicFieldLabelName } from "../../utils/sectionFieldsAndLabelsUtils";
import { toDisplayCompanyDetailsSubSection } from "./../../employment/utils/toDisplayCompanyDetailsSubSection";
import { toDisplayHrDetailsSubSection } from "./../../employment/utils/toDisplayHrDetailsSubSection";
import { toDisplaySupervisorDetailsSubSection } from "./../../employment/utils/toDisplaySupervisorDetailsSubSection";
import { toDisplaySalaryDetailsSubSection } from "./../../employment/utils/toDisplaySalaryDetailsSubSection";

export const employmentHeaders = (
  sectionInputFields = "",
  hrOrganizationName = ""
) => {
  let allFields = [
    {
      value: "employmentCompanyName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("employmentCompanyName", sectionInputFields)
        : "Company name*",
    },
    {
      value: "employmentFromDate",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("employmentFromDate", sectionInputFields)
        : "From date*",
    },
    {
      value: "employmentToDate",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("employmentToDate", sectionInputFields)
        : "To date*",
    },
    {
      value: "isCurrentEmployer",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("isCurrentEmployer", sectionInputFields)
        : "Is this your current employer?*",
    },
    {
      value: "canContactEmployer",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("canContactEmployer", sectionInputFields)
        : "Can we contact this employer now?*",
    },
    {
      value: "cessationDate",
      text: "What is your official cessation date?*",
    },
    {
      value: "reasonOfChoosingLateCessationDate",
      text: `Please provide your reason of choosing the date more than a month.The reason will also be visible to ${
        hrOrganizationName || "your organization"
      }.*`,
    },
    {
      value: "employmentTypeName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("employmentTypeId", sectionInputFields)
        : "Employment type*",
    },
    {
      value: "employmentStatusName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("employmentStatusId", sectionInputFields)
        : "Employment status*",
    },
    {
      value: "employmentDepartment",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("employmentDepartment", sectionInputFields)
        : "Employment department",
    },
    {
      value: "jobTitle",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("jobTitle", sectionInputFields)
        : "Job title*",
    },
    {
      value: "employeeId",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("employeeId", sectionInputFields)
        : "Employee ID",
    },
    {
      value: "reasonOfLeavingName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("reasonForLeaving", sectionInputFields)
        : "Reason for leaving*",
    },
    {
      value: "otherReasonForLeaving",
      text: "Other reason for leaving*",
    },
    {
      value: "wasResignationRequested",
      text: "Was the resignation required by this employer?*",
    },
    {
      value: "reasonOfResignationRequested",
      text: "Please provide the reason why your company has requested resignation/separation*",
    },
    // {
    //   heading: true,
    //   text: "Employment {{index}} - Agency Details",
    // },
    {
      heading: true,
      text: "Agency Details",
    },
    {
      value: "wasOnPayrollOfAgency",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("wasOnPayrollOfAgency", sectionInputFields)
        : "Were you employed by an agency for this employment?*",
    },
    {
      value: "agencyName",
      text: "Agency Name*",
    },
    {
      value: "agencyStreetAddressLine1",
      // text: "Street/Address Line 1*",
      text: "Address Line 1*",
    },
    {
      value: "agencyStreetAddressLine2",
      // text: "Street/Address Line 2",
      text: "Address Line 2",
    },
    {
      value: "agencyCityTownDistrict",
      text: "City/Town/District*",
    },
    {
      value: "agencyStateProvince",
      text: "State/Province*",
    },
    {
      value: "agencyCountryName",
      text: "Country*",
    },
    {
      value: "agencyZipPostalAreaCode",
      text: "Zip/Postal/Area Code",
    },
    {
      value: "agencyPhoneNumber",
      text: "Phone number",
    },
    // {
    //   heading: true,
    //   text: "Employment {{index}} - Company Details",
    // },
    {
      heading: true,
      text: "Company Details",
    },
    {
      value: "employerStreetAddressLine1",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName(
            "employerStreetAddressLine1",
            sectionInputFields
          )
        : "Address Line 1*",
    },
    {
      value: "employerStreetAddressLine2",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName(
            "employerStreetAddressLine2",
            sectionInputFields
          )
        : "Address Line 2",
    },
    {
      value: "employerCityTownDistrict",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName(
            "employerCityTownDistrict",
            sectionInputFields
          )
        : "City/Town/District*",
    },
    {
      value: "employerStateProvince",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("employerStateProvince", sectionInputFields)
        : "State/Province*",
    },
    {
      value: "employerCountryName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("employerCountryId", sectionInputFields)
        : "Country*",
    },
    {
      value: "employerZipPostalAreaCode",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName(
            "employerZipPostalAreaCode",
            sectionInputFields
          )
        : "Zip/Postal/Area Code",
    },
    {
      value: "employerPhoneNumber",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("employerPhoneNumber", sectionInputFields)
        : "Phone number*",
    },
    // {
    //   heading: true,
    //   text: "Employment {{index}} - HR Details",
    // },
    {
      heading: true,
      text: "HR Details",
    },
    {
      value: "hrName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("hrName", sectionInputFields)
        : "HR name",
    },
    {
      value: "hrTitle",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("hrTitle", sectionInputFields)
        : "HR title",
    },
    {
      value: "hrEmail",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("hrEmail", sectionInputFields)
        : "HR email",
    },
    {
      value: "hrPhoneNumber",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("hrPhoneNumber", sectionInputFields)
        : "HR Phone/Contact number",
    },
    // {
    //   heading: true,
    //   text: "Employment {{index}} - Supervisor Details",
    // },
    {
      heading: true,
      text: "Supervisor Details",
    },
    {
      value: "supervisorName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("supervisorName", sectionInputFields)
        : "Supervisor name",
    },
    {
      value: "supervisorTitle",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("supervisorTitle", sectionInputFields)
        : "Supervisor title",
    },
    {
      value: "supervisorEmail",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("supervisorEmail", sectionInputFields)
        : "Supervisor email",
    },
    {
      value: "supervisorPhoneNumber",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("supervisorPhoneNumber", sectionInputFields)
        : "Phone/Contact number",
    },

    // {
    //   heading: true,
    //   text: "Employment {{index}} - Salary Details",
    // },
    {
      heading: true,
      text: "Salary Details",
    },
    {
      value: "salaryFrequencyName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("salaryFrequencyId", sectionInputFields)
        : "Salary frequency*",
    },
    {
      value: "otherSalaryFrequency",
      text: "Other salary frequency*",
    },
    {
      value: "salaryCurrencyName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("salaryCurrencyId", sectionInputFields)
        : "Salary currency*",
    },
    {
      value: "salaryAmount",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("salaryAmount", sectionInputFields)
        : "Salary amount*",
    },
    {
      value: "otherRemuneration",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("otherRemuneration", sectionInputFields)
        : "Other Remuneration(s)",
    },
    // {
    //   heading: true,
    //   text: "Employment {{index}} - Bonus Details",
    // },
    {
      heading: true,
      text: "Bonus Details",
    },
    {
      value: "wasBonusReceived",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("wasBonusReceived", sectionInputFields)
        : "Did you received bonus from company?*",
    },
    {
      headerArray: true,
      text: "Bonus",
      value: "bonus",
      headerValues: [
        {
          value: "bonusCurrencyName",
          text: "Currency*",
        },
        {
          value: "bonusAmount",
          text: "Last bonus amount*",
        },
        {
          value: "bonusName",
          text: "Bonus type*",
        },
        {
          value: "otherBonusTypeName",
          text: "Other bonus type*",
        },
      ],
    },
    {
      value: "EMPLOYMENT_DOCS",
      text: "Appointment / Resignation / Relieving / Experience letter",
    },
    {
      value: "EMPLOYMENT_PAYSLIPS",
      text: "Latest payslips",
    },
  ];

  let removeHeadings = [];

  //checking if any of the fields under this section is present or not to display the heading in review
  let isAgencyDetailsFieldsPresent = sectionInputFields?.length
    ? getDynamicFieldLabelName("wasOnPayrollOfAgency", sectionInputFields)
    : true;

  if (!isAgencyDetailsFieldsPresent) {
    removeHeadings.push("Agency Details");
  }

  let isCompanyDetailsfieldsPresent = sectionInputFields?.length
    ? toDisplayCompanyDetailsSubSection(sectionInputFields)
    : true;

  if (!isCompanyDetailsfieldsPresent) {
    removeHeadings.push("Company Details");
  }

  let isHrDetailsfieldsPresent = sectionInputFields?.length
    ? toDisplayHrDetailsSubSection(sectionInputFields)
    : true;

  if (!isHrDetailsfieldsPresent) {
    removeHeadings.push("HR Details");
  }

  let isSupervisorDetailsfieldsPresent = sectionInputFields?.length
    ? toDisplaySupervisorDetailsSubSection(sectionInputFields)
    : true;

  if (!isSupervisorDetailsfieldsPresent) {
    removeHeadings.push("Supervisor Details");
  }

  let isSalaryDetailsfieldsPresent = sectionInputFields?.length
    ? toDisplaySalaryDetailsSubSection(sectionInputFields)
    : true;

  if (!isSalaryDetailsfieldsPresent) {
    removeHeadings.push("Salary Details");
  }

  let isBonusDetailsfieldsPresent = sectionInputFields?.length
    ? getDynamicFieldLabelName("wasBonusReceived", sectionInputFields)
    : true;

  if (!isBonusDetailsfieldsPresent) {
    removeHeadings.push("Bonus Details");
  }

  //for candidate section, the 'text' field will be 'undefined' if no field is present in welcome API
  //so we will filter them out to not display an empty row on review section
  let finalFields = allFields?.filter(
    (curr) => curr?.text && !removeHeadings?.includes(curr?.text)
  );

  return finalFields;
};
