import { createSectionInitialDetailsFields } from "../../utils/createSectionInitialDetailsFields";

export const newFamilyDetails = (inputFields) => {

  let allFields = {
    fathersFirstName: "",
    fathersMiddleName: "",
    fathersLastName: "",
    mothersFirstName: "",
    mothersMiddleName: "",
    mothersLastName: "",
    spouseFirstName: "",
    spouseMiddleName: "",
    spouseLastName: "",
    mothersMaidenNameExists: {
      mothersMaidenNameExists: false,
      mothersMaidenName: "",
    },
  };

  return createSectionInitialDetailsFields(inputFields, allFields);
};
