import * as Yup from "yup";
import { createSectionSchema } from "../../utils/createSectionSchema";
import { addressFieldsSchema } from "./allFieldsSchema";

export const addressValidationSchema = (inputFields, initialValues) => {
  let finalSchema = createSectionSchema(
    initialValues[0],
    inputFields,
    addressFieldsSchema
  );

  return Yup.object({
    addresses: Yup.array().of(Yup.object().shape(finalSchema)),
  });
};
