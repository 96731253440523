import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { setSelectedOrg } from "../../../store/actions/systemAdminActions";

import {
  GET_OPS_USERS_BY_ORG,
  GET_ORGANIZATION_USERS,
} from "../../../store/actions/actionTypes";
import CustomTooltip from "../../common/CustomTooltip";
import AttributionIcon from "@mui/icons-material/Attribution";

const ClientSelectOrgComponent = () => {
  const dispatch = useDispatch();
  const { orgsLists, selectedOrg } = useSelector((state) => state.systemAdmin);

  return (
    <Grid
      item
      xs={12}
      container
      direction={{ xs: "column", sm: "row" }}
      justifyContent={{ xs: "center" }}
      alignItems="center"
      mb={2}
      mt={{ xs: 10, md: 0 }}
    >
      <Typography mb={{ xs: 1, md: 0 }}>
        Select Organization : &nbsp;
      </Typography>

      <Autocomplete
        disablePortal
        disableClearable
        id="org-selection"
        options={orgsLists}
        sx={{ width: { md: 450, xs: 250 } }}
        defaultValue={orgsLists[0]}
        onChange={(e, newValue) => {
          if (newValue) {
            dispatch(setSelectedOrg(newValue.hrOrganizationsId));
            //on selection of new org , we will make hr users lists & ops users lists
            //to null so that api only gets called once
            dispatch({ type: GET_ORGANIZATION_USERS, payload: null });
            dispatch({ type: GET_OPS_USERS_BY_ORG, payload: null });
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={
              orgsLists?.find(
                (curr) => curr?.hrOrganizationsId === +selectedOrg
              )?.isVendor
                ? "Vendor"
                : "Organization"
            }
          />
        )}
        getOptionLabel={(option) => option.hrOrganizationName || ""}
        filterOptions={(options, { inputValue }) =>
          inputValue?.length
            ? options?.filter((option) =>
                option.hrOrganizationName
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              )
            : options
        }
        renderOption={(props, item) => (
          <li {...props} key={item.hrOrganizationsId}>
            <Box width="10%" display="flex" justifyContent="center">
              {item?.isVendor && (
                <CustomTooltip title="Vendor">
                  <AttributionIcon color="primary" />
                </CustomTooltip>
              )}
            </Box>
            <Box>
              {item?.hrOrganizationName?.length > 35 ? (
                <CustomTooltip
                  tooltipmaxwidth={400}
                  title={item?.hrOrganizationName}
                >
                  <Box component="span">{`${item?.hrOrganizationName.slice(
                    0,
                    30
                  )}...`}</Box>
                </CustomTooltip>
              ) : (
                item?.hrOrganizationName
              )}
            </Box>
          </li>
        )}
      />
    </Grid>
  );
};

export default ClientSelectOrgComponent;
