import { createSectionInitialDetailsFields } from "../../utils/createSectionInitialDetailsFields";

export const newIdentity = (inputFields) => {
  let allFields = {
    birthCountryId: null,
    primaryNationalityId: null,
    residenceCountryId: null,
    identityDocumentType: {
      identityDocumentType: "",
      identityDocumentNumber: "",
      identityAttachments: [],
    },
  };
  return createSectionInitialDetailsFields(inputFields, allFields);
};
