import * as Yup from "yup";
import { createSectionSchema } from "../../utils/createSectionSchema";
import { empFieldsSchema, empGapFieldsSchema } from "./allFieldsSchema";

export const emplomentValidationSchema = (
  checkGap,
  inputFields,
  employments,
  employmentsGaps
) => {
  //mapping initialValues field name to that of inputFields field name so that their schema can be included.
  let mappingSchemaFieldsToDisplayInputFields = {
    cessationDate: "canContactEmployer",
    reasonOfChoosingLateCessationDate: "canContactEmployer",
    otherReasonForLeaving: "reasonForLeaving",
    wasResignationRequested: "reasonForLeaving",
    reasonOfResignationRequested: "reasonForLeaving",
    agencyName: "wasOnPayrollOfAgency",
    agencyStreetAddressLine1: "wasOnPayrollOfAgency",
    agencyStreetAddressLine2: "wasOnPayrollOfAgency",
    agencyCityTownDistrict: "wasOnPayrollOfAgency",
    agencyStateProvince: "wasOnPayrollOfAgency",
    agencyZipPostalAreaCode: "wasOnPayrollOfAgency",
    agencyCountryId: "wasOnPayrollOfAgency",
    agencyPhoneCountryCode: "wasOnPayrollOfAgency",
    agencyPhoneNumber: "wasOnPayrollOfAgency",
    bonus: "wasBonusReceived",
    otherSalaryFrequency: "salaryFrequencyId",
  };

  let empSchema = createSectionSchema(
    employments[0],
    inputFields,
    empFieldsSchema,
    mappingSchemaFieldsToDisplayInputFields
  );

  let finalEmpGapFieldsSchema = empGapFieldsSchema(checkGap);

  let empGapSchema = createSectionSchema(
    employmentsGaps[0],
    inputFields,
    finalEmpGapFieldsSchema,
    mappingSchemaFieldsToDisplayInputFields
  );

  return Yup.object({
    employments: Yup.array().of(Yup.object().shape(empSchema)),
    employmentsGaps: Yup.array().of(Yup.object().shape(empGapSchema)),
  });
};
