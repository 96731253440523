import { setToastNotification } from "../../../../store/actions/toastNotificationActions";
import { ERROR } from "../../../../store/constant";
import { getCurrentFileNameAndFunction } from "../../../../utils/getCurrentFileNameAndFunction";

export const checkFileNotUploadedError = (
  section,
  qualifications,
  setAttachFileErr,
  dispatch
) => {
  let fileUploadError = [];
  let fileNotUploadedSections = [];

  qualifications.map((qualification, index) => {
    if (qualification.attachments.length === 0) {
      fileUploadError.push({
        errFileName: `qualifications.${index}.attachments`,
      });
      fileNotUploadedSections.push(`${section} ${index + 1}`);
    }
  });

  setAttachFileErr([...fileUploadError]);

  if (fileNotUploadedSections?.length) {
    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "checkFileNotUploadedError"
    );

    dispatch(
      setToastNotification(
        ERROR,
        `Please upload a file for ${fileNotUploadedSections?.join(", ")}`,
        logDetails
      )
    );
  }

  return fileNotUploadedSections;
};
