import * as Yup from "yup";
import { familyDetailsFieldsSchema } from "./allFieldsSchema";
import { createSectionSchema } from "../../utils/createSectionSchema";

export const familyValidationSchema = (inputFields, initialValues) => {
  let mappingSchemaFieldsToDisplayInputFields = {
    mothersMaidenName: "mothersMaidenNameExists",
  };

  let finalSchema = createSectionSchema(
    initialValues,
    inputFields,
    familyDetailsFieldsSchema,
    mappingSchemaFieldsToDisplayInputFields
  );

  return Yup.object().shape(finalSchema);
};
