import {
  Box,
  FormControlLabel,
  styled,
  Switch,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { UPDATE_DYNAMIC_INPUT_FIELDS_SETTINGS } from "../../../../../store/actions/actionTypes";
import { cloneDeep } from "lodash";
import { useDispatch } from "react-redux";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-track": {
    height: "15px",
    width: "25px",
  },
  "& .MuiSwitch-thumb": {
    height: "10px",
    width: "10px",
  },
  "& .MuiSwitch-switchBase": {
    transform: "translateX(0px) translateY(.5px)",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    transform: "translateX(10px) translateY(.5px)",
  },
}));

const SingleInputFieldSettings = ({ name, orientation = "vertical" }) => {
  const { dynamicInputFields, defaultInputFieldsByCriteria } = useSelector(
    (state) => state.systemAdmin
  );
  const dispatch = useDispatch();

  let { id, inputFieldDisplayName, toDisplay, isMandatory, isConflict } =
    useMemo(() => {
      let isPresent = dynamicInputFields?.find(
        (curr) => curr?.inputFieldKey === name
      );

      if (isPresent) {
        return ({
          id,
          inputFieldDisplayName,
          toDisplay,
          isMandatory,
          isConflict,
        } = isPresent);
      } else {
        return {
          id: null,
          inputFieldDisplayName: "",
        };
      }
    }, [dynamicInputFields]);

  const handleDisplayChange = useCallback(
    (toUpdatekey) => {
      let updatedDynamicInputFields = cloneDeep(dynamicInputFields)?.map(
        (field) => {
          if (field.id === id) {
            field[toUpdatekey] = !field[toUpdatekey];

            let fieldForMatchtoCheckConflict =
              defaultInputFieldsByCriteria?.find(
                (f) =>
                  f.candidateProfileInputFieldId ===
                  (field.candidateProfileInputFieldId || field.id)
              );

            if (
              (!fieldForMatchtoCheckConflict && field.toDisplay) ||
              (fieldForMatchtoCheckConflict &&
                (fieldForMatchtoCheckConflict.isMandatory !==
                  field.isMandatory ||
                  !field.toDisplay))
            ) {
              field.isConflict = true;
            } else {
              field.isConflict = false;
            }
          }
          return field;
        }
      );

      dispatch({
        type: UPDATE_DYNAMIC_INPUT_FIELDS_SETTINGS,
        payload: updatedDynamicInputFields,
      });
    },
    [dynamicInputFields]
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap={1}
      border={(theme) => `1px solid ${theme.palette.grey[400]}`}
      borderRadius={2}
      pt={1}
      height="100%"
      backgroundColor={isConflict && "#e2a1a1"}
    >
      <Typography fontSize="11px" textAlign="center" fontWeight={550}>
        {inputFieldDisplayName}
      </Typography>
      <Box
        display="flex"
        alignItems="baseline"
        flexDirection={orientation === "vertical" ? "column" : "row"}
      >
        <FormControlLabel
          onChange={() => handleDisplayChange("toDisplay")}
          control={<CustomSwitch checked={toDisplay} />}
          label={
            <Typography
              sx={{
                fontSize: "10px",
                margin: `-12px ${
                  orientation === "vertical" ? "23px" : "5px"
                } 0 0`,
              }}
            >
              Display
            </Typography>
          }
          labelPlacement="start"
          sx={{ marginBottom: orientation === "vertical" ? "5px" : 0 }}
        />
        <Box
          component="span"
          mr={0.5}
          display={orientation === "vertical" ? "none" : "block"}
        >
          |
        </Box>
        <FormControlLabel
          onChange={() => handleDisplayChange("isMandatory")}
          control={<CustomSwitch checked={isMandatory} />}
          label={
            <Typography sx={{ fontSize: "10px", margin: "-12px 5px 0 0" }}>
              Mandatory
            </Typography>
          }
          labelPlacement="start"
          style={{ fontSize: "8px", margin: "-10px 5px 0 0" }}
        />
      </Box>{" "}
    </Box>
  );
};

export default SingleInputFieldSettings;
