import { Box, Divider, Grid, Typography } from "@mui/material";
import SingleInputFieldSettings from "../components/SingleInputFieldSettings";

const ReferenceSkeleton = () => {
  return (
    <Box maxWidth={1000} margin="0 auto">
      <Grid container spacing={1} mb={1}>
        <Grid item xs={12} md={6}>
          <SingleInputFieldSettings
            
            name="noOfProfessionalReference"
            orientation="horizontal"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SingleInputFieldSettings
            
            name="reasonForLessNoOfReference"
            orientation="horizontal"
          />
        </Grid>
      </Grid>

      <Divider sx={{ margin: "10px" }} />

      <Grid container spacing={1} mb={1}>
        <Grid item xs={12} md={4}>
          <SingleInputFieldSettings
            
            name="fullName"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <SingleInputFieldSettings
            
            name="title"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <SingleInputFieldSettings
            
            name="referanceCompanyName"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <SingleInputFieldSettings
            
            name="professionalRelationshipId"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <SingleInputFieldSettings
            
            name="city"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <SingleInputFieldSettings
            
            name="referanceCountryId"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <SingleInputFieldSettings
            
            name="email"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <SingleInputFieldSettings
            
            name="referencePhoneNumber"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12}>
          <SingleInputFieldSettings
            
            name="referenceAdditionalComments"
            orientation="horizontal"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReferenceSkeleton;
