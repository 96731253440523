import * as Yup from "yup";
import { commonStringSchema } from "../../utils/commonFieldSchema";

export const familyDetailsFieldsSchema = {
  fathersFirstName: (isMandatory) =>
    commonStringSchema(isMandatory, "Father's First Name is required"),
  fathersMiddleName: (isMandatory) =>
    commonStringSchema(isMandatory, "Father's Middle Name is required"),
  fathersLastName: (isMandatory) =>
    commonStringSchema(isMandatory, "Father's Last Name is required"),
  mothersFirstName: (isMandatory) =>
    commonStringSchema(isMandatory, "Mother's First Name is required"),
  mothersMiddleName: (isMandatory) =>
    commonStringSchema(isMandatory, "Mother's Middle Name is required"),
  mothersLastName: (isMandatory) =>
    commonStringSchema(isMandatory, "Mother's Last Name is required"),
  spouseFirstName: (isMandatory) =>
    commonStringSchema(isMandatory, "Spouse's First Name is required"),
  spouseMiddleName: (isMandatory) =>
    commonStringSchema(isMandatory, "Spouse's Middle Name is required"),
  spouseLastName: (isMandatory) =>
    commonStringSchema(isMandatory, "Spouse's Last Name is required"),
  mothersMaidenNameExists: (isMandatory) => Yup.boolean(),
  mothersMaidenName: (isMandatory) =>
    Yup.mixed().when("mothersMaidenNameExists", {
      is: true,
      then: commonStringSchema(isMandatory, "Mother's maiden name is required"),
    }),
};
