export const createFinalSectionInitialValue = (
  currentInitialValues,
  originalInitialValues
) => {
  let finalInitialValues = {};

  //keys present in sections who's value is to be converted into Boolean
  let keysWithBooleanValues = {
    otherNameExists: "otherNameExists", //personal
    formerNameExists: "formerNameExists", //personal
    nickNameExists: "nickNameExists", //personal
    driverLicenseNumberExists: "driverLicenseNumberExists", //personal
    DINNumberExists: "DINNumberExists", //personal
    mothersMaidenNameExists: "mothersMaidenNameExists", //family
    isCurrentEmployer: "isCurrentEmployer", //employment
    canContactEmployer: "canContactEmployer", //employment
    wasResignationRequested:'wasResignationRequested', //employment
    wasOnPayrollOfAgency: "wasOnPayrollOfAgency", //employment
    wasBonusReceived: "wasBonusReceived", //employment
    isCurrentlyStudying: "isCurrentlyStudying", //education
  };

  for (let key in originalInitialValues) {
    let value = currentInitialValues[key];

    finalInitialValues = {
      ...finalInitialValues,
      [key]: keysWithBooleanValues[key] ? Boolean(value) : value,
    };
  }

  if (currentInitialValues?.attachments) {
    finalInitialValues.attachments = currentInitialValues.attachments;
  }

  return finalInitialValues;
};
