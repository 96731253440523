import { Box, Typography } from "@mui/material";
import { memo, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  GET_SUB_CHECKS_LIST,
  REMOVE_CHECK_AND_SUBCHECK,
  UPDATE_CHECK_SELECTED_STATE,
  UPDATE_SUBCHECK_SELECTED_STATE,
} from "../../../../../../../../store/actions/actionTypes";
import { toNotAllowSubCheckAddition } from "../utils/toNotAllowSubCheckAddition";
import { useDispatch } from "react-redux";
import { isEqual } from "lodash";
import AddSubCheck from "../AddSubCheck";
import { useSearchParams } from "react-router-dom";

const CheckLists = memo(
  ({ caseCheck }) => {
    const {
      subChecksList,
      addedChecksAndSubCheck,
      allSubChecksLists,
      selectedCheckId,
      selectedSubCheckId,
      OpsCandidateCaseChecksList,
    } = useSelector((state) => state.operations);

    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
      if (searchParams.get("selectedCheckId")) {
        handleSelectCheck(+searchParams.get("selectedCheckId"));
      }
    }, []);

    //removing added subcheck
    const removeSubCheck = () => {
      handleUpdateReduxConstant(
        UPDATE_SUBCHECK_SELECTED_STATE,
        subChecksList[0]?.id
      );

      dispatch({
        type: REMOVE_CHECK_AND_SUBCHECK,
        payload: caseCheck?.checksId,
      });
    };

    //handles onclick on check to select
    const handleSelectCheck = (checkId) => {
      let subCheckLists = [];
      allSubChecksLists?.forEach((curr) => {
        if (curr?.candidatesChecksMappingId === checkId) {
          subCheckLists.push(curr);
        }
      });

      //setting selectedSubCheckId state to first value from subChecksList
      handleUpdateReduxConstant(
        UPDATE_SUBCHECK_SELECTED_STATE,
        subCheckLists[0]?.id
      );

      handleUpdateReduxConstant(GET_SUB_CHECKS_LIST, subCheckLists);
      handleUpdateReduxConstant(UPDATE_CHECK_SELECTED_STATE, checkId);

      //updating check id and subcheck id url params
      setSearchParams((prevParams) => {
        return new URLSearchParams({
          ...Object.fromEntries(prevParams.entries()),
          selectedCheckId: +checkId,
          selectedSubCheckId: +subCheckLists[0]?.id,
        });
      });
      scrollBackUp();
    };

    //handles onclick on sub-check to select
    const handleSelectSubCheck = (selectedSubCheckId) => {
      handleUpdateReduxConstant(
        UPDATE_SUBCHECK_SELECTED_STATE,
        selectedSubCheckId
      );

      //updating subcheck id url params
      setSearchParams((prevParams) => {
        return new URLSearchParams({
          ...Object.fromEntries(prevParams.entries()),
          selectedSubCheckId: +selectedSubCheckId,
        });
      });

      scrollBackUp();
    };

    //custom function to update redux state
    const handleUpdateReduxConstant = (CONSTANT, value) => {
      dispatch({
        type: CONSTANT,
        payload: value,
      });
    };

    const scrollBackUp = () => {
      //scrolling back to the top of subcheck details
      const scrollContainer = document.querySelector(
        "#ops-candidate-select-check-or-subcheck-scroll"
      );

      if (scrollContainer) {
        scrollContainer.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    };

    let currentSelectedCheckOnHold = useMemo(() => {
      return OpsCandidateCaseChecksList?.find(
        (curr) => curr?.candidatesChecksMappingId === selectedCheckId
      )?.onHold;
    }, [OpsCandidateCaseChecksList, selectedCheckId]);

    return (
      <>
        {selectedCheckId === caseCheck?.candidatesChecksMappingId ? (
          <Box
            sx={{
              border: (theme) => `1px solid ${theme.palette.grey[400]}`,
              borderRadius: "5px",
            }}
            px={0.5}
            py={1}
            mb={1}
          >
            <Box>
              <Box
                sx={{
                  border: (theme) => `1px solid ${theme.palette.grey[400]}`,
                  borderRadius: "5px",
                  background: (theme) => theme.palette.primary.main,
                }}
                p={0.5}
              >
                <Typography
                  fontWeight="550"
                  fontSize="12px"
                  color="#fff"
                  pl={1}
                >
                  {caseCheck?.checkName}
                </Typography>
              </Box>
              {subChecksList?.length && !currentSelectedCheckOnHold
                ? subChecksList?.map((subCheck, index) => (
                    <Box key={subCheck?.id} display="flex" ml={3}>
                      <Box
                        sx={{
                          borderLeft: (theme) =>
                            `1px dashed ${theme.palette.grey[400]}`,
                          minHeight:
                            index === subChecksList?.length - 1 ? "none" : 25,
                          height:
                            index === subChecksList?.length - 1 ? 25 : "none",
                        }}
                      />
                      <Box
                        marginTop="25px"
                        sx={{
                          borderTop: (theme) =>
                            `1px dashed ${theme.palette.grey[400]}`,
                          minWidth: 25,
                        }}
                      />
                      <Box
                        marginTop="12.5px"
                        sx={{
                          border: (theme) =>
                            `1px solid ${theme.palette.grey[400]}`,
                          borderRadius: "5px",
                          background: (theme) =>
                            selectedSubCheckId === subCheck?.id
                              ? theme.palette.primary[100]
                              : "none",
                          cursor: "pointer",
                        }}
                        p={0.5}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSelectSubCheck(subCheck?.id);
                        }}
                      >
                        <Typography
                          fontWeight="550"
                          fontSize="12px"
                          sx={{ wordBreak: "break-word" }}
                        >
                          {subCheck?.subCheckDisplayName}
                        </Typography>
                      </Box>
                    </Box>
                  ))
                : null}
            </Box>
            {!currentSelectedCheckOnHold &&
            !toNotAllowSubCheckAddition?.find(
              (c) => c.checksId === caseCheck?.checksId
            ) ? (
              <AddSubCheck
                removeSubCheck={removeSubCheck}
                caseCheck={caseCheck}
              />
            ) : null}
          </Box>
        ) : (
          <Box
            sx={{
              border: (theme) => `1px solid ${theme.palette.grey[400]}`,
              borderRadius: "5px",
              cursor: "pointer",
            }}
            px={0.5}
            py={1}
            mb={1}
            onClick={(e) => {
              e.stopPropagation();
              handleSelectCheck(caseCheck?.candidatesChecksMappingId);
            }}
          >
            <Typography
              fontWeight="550"
              fontSize="12px"
              pl={1}
              sx={{ wordBreak: "break-word" }}
            >
              {caseCheck?.checkName}
            </Typography>
          </Box>
        )}
      </>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps)
);

export default CheckLists;
