import { FastField, FieldArray, Form } from "formik";
import React from "react";
import DisplayHeadingForMultipleFields from "../../common/DisplayHeadingForMultipleFields";
import { checkGaps } from "../utils/checkGaps";
import { Box, Grid, Typography } from "@mui/material";
import {
  BaseCountrySelect,
  BaseDatePicker,
  BaseTextField,
} from "../../../base";
import moment from "moment";
import {
  checkIfFieldToBeDisplayedOrNot,
  getDynamicFieldLabelName,
} from "./../../utils/sectionFieldsAndLabelsUtils";

const AddressFormDetails = ({
  form,
  inputFields,
  pushNewAddressRef,
  addressForm,
  sectionData,
  setGaps,
}) => {
  return (
    <FieldArray name="addresses" id="addresses">
      {({ push, remove }) => {
        pushNewAddressRef.current = push;
        return (
          <>
            {form?.values?.addresses?.length ? (
              <>
                {form?.values?.addresses?.map((address, index) =>
                  address.selectedTab ? (
                    <>
                      <DisplayHeadingForMultipleFields
                        key={index}
                        index={index}
                        heading="Address"
                        currentValue={address}
                        sectionValues={form?.values?.addresses}
                        fieldArrayName="addresses"
                        setFieldValue={form.setFieldValue}
                        runWhenDelete={() =>
                          checkGaps(
                            addressForm.current.values,
                            sectionData,
                            setGaps
                          )
                        }
                      />
                      <Box px={3} pb={3}>
                        <Grid container spacing={2}>
                          {" "}
                          {checkIfFieldToBeDisplayedOrNot(
                            "streetAddressLine1",
                            inputFields
                          ) && (
                            <Grid item xs={12} md={6}>
                              <FastField
                                component={BaseTextField}
                                label={getDynamicFieldLabelName(
                                  "streetAddressLine1",
                                  inputFields
                                )}
                                name={`addresses.${index}.streetAddressLine1`}
                              />
                            </Grid>
                          )}
                          {checkIfFieldToBeDisplayedOrNot(
                            "streetAddressLine2",
                            inputFields
                          ) && (
                            <Grid item xs={12} md={6}>
                              <FastField
                                component={BaseTextField}
                                label={getDynamicFieldLabelName(
                                  "streetAddressLine2",
                                  inputFields
                                )}
                                name={`addresses.${index}.streetAddressLine2`}
                              />
                            </Grid>
                          )}
                        </Grid>
                        <Grid
                          container
                          pt={{ xs: 1, md: 3 }}
                          spacing={{ xs: 1, md: 4 }}
                        >
                          {checkIfFieldToBeDisplayedOrNot(
                            "cityTownDistrict",
                            inputFields
                          ) && (
                            <Grid item xs={12} md={4}>
                              <FastField
                                component={BaseTextField}
                                label={getDynamicFieldLabelName(
                                  "cityTownDistrict",
                                  inputFields
                                )}
                                name={`addresses.${index}.cityTownDistrict`}
                              />
                            </Grid>
                          )}
                          {checkIfFieldToBeDisplayedOrNot(
                            "stateProvince",
                            inputFields
                          ) && (
                            <Grid item xs={12} md={4}>
                              <FastField
                                component={BaseTextField}
                                name={`addresses.${index}.stateProvince`}
                                shouldUpdate={() => true}
                                label={`${getDynamicFieldLabelName(
                                  "stateProvince",
                                  inputFields,
                                  false
                                )}${
                                  form?.values?.addresses[index]
                                    ?.addressCountryId === 101 ||
                                  form?.values?.addresses[index]
                                    ?.addressCountryId === 132
                                    ? ""
                                    : "*"
                                }`}
                              />
                            </Grid>
                          )}
                          {checkIfFieldToBeDisplayedOrNot(
                            "addressCountryId",
                            inputFields
                          ) && (
                            <Grid item xs={12} md={4}>
                              <FastField
                                component={BaseCountrySelect}
                                label={getDynamicFieldLabelName(
                                  "addressCountryId",
                                  inputFields
                                )}
                                name={`addresses.${index}.addressCountryId`}
                              />
                            </Grid>
                          )}
                        </Grid>
                        <Grid
                          container
                          pt={{ xs: 1, md: 3 }}
                          spacing={{ xs: 1, md: 4 }}
                        >
                          {checkIfFieldToBeDisplayedOrNot(
                            "zipPostalAreaCode",
                            inputFields
                          ) && (
                            <Grid item xs={12} md={4}>
                              <FastField
                                component={BaseTextField}
                                label={getDynamicFieldLabelName(
                                  "zipPostalAreaCode",
                                  inputFields
                                )}
                                name={`addresses.${index}.zipPostalAreaCode`}
                              />
                            </Grid>
                          )}
                          {checkIfFieldToBeDisplayedOrNot(
                            "addressFromDate",
                            inputFields
                          ) && (
                            <Grid item xs={12} md={4}>
                              <FastField
                                component={BaseDatePicker}
                                label={getDynamicFieldLabelName(
                                  "addressFromDate",
                                  inputFields
                                )}
                                views={["year", "month"]}
                                name={`addresses.${index}.addressFromDate`}
                                maxDate={
                                  moment(
                                    sectionData?.section?.validationScope
                                      ?.endDate
                                  ).addressToDate
                                }
                                checkGaps={checkGaps}
                                index={index}
                                sectionData={sectionData}
                                setGapsState={setGaps}
                              />
                            </Grid>
                          )}

                          {checkIfFieldToBeDisplayedOrNot(
                            "addressToDate",
                            inputFields
                          ) && (
                            <Grid item xs={12} md={4}>
                              <FastField
                                component={BaseDatePicker}
                                label={getDynamicFieldLabelName(
                                  "addressToDate",
                                  inputFields
                                )}
                                presentBtn
                                views={["year", "month"]}
                                name={`addresses.${index}.addressToDate`}
                                maxDate={
                                  moment(
                                    sectionData?.section?.validationScope
                                      ?.endDate
                                  ).addressToDate
                                }
                                checkGaps={checkGaps}
                                sectionData={sectionData}
                                setGapsState={setGaps}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </>
                  ) : null
                )}
              </>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography align="center" fontWeight={550} p={2}>
                  No Addresses Present
                </Typography>
              </Box>
            )}
          </>
        );
      }}
    </FieldArray>
  );
};

export default AddressFormDetails;
