import { Box, Divider, Grid, Typography } from "@mui/material";
import SingleInputFieldSettings from "../components/SingleInputFieldSettings";

const EmploymentSkeletion = () => {
  return (
    <Box maxWidth={1000} margin="0 auto">
      <Grid container xs={12} spacing={1} mb={1}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <SingleInputFieldSettings
                name="employmentCompanyName"
                orientation="horizontal"
              />
            </Grid>

            <Grid item xs={12}>
              <SingleInputFieldSettings name="canContactEmployer" />
            </Grid>

            <Grid item xs={12}>
              <SingleInputFieldSettings
                name="employmentTypeId"
                orientation="horizontal"
              />
            </Grid>

            <Grid item xs={12}>
              <SingleInputFieldSettings
                name="employmentDepartment"
                orientation="horizontal"
              />
            </Grid>

            <Grid item xs={12}>
              <SingleInputFieldSettings
                name="employeeId"
                orientation="horizontal"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <SingleInputFieldSettings
                name="isCurrentEmployer"
                orientation="horizontal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SingleInputFieldSettings name="employmentFromDate" />
            </Grid>
            <Grid item xs={12} md={6}>
              <SingleInputFieldSettings name="employmentToDate" />
            </Grid>

            <Grid item xs={12}>
              <SingleInputFieldSettings
                name="employmentStatusId"
                orientation="horizontal"
              />
            </Grid>

            <Grid item xs={12}>
              <SingleInputFieldSettings
                name="jobTitle"
                orientation="horizontal"
              />
            </Grid>

            <Grid item xs={12}>
              <SingleInputFieldSettings
                name="reasonForLeaving"
                orientation="horizontal"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ margin: "10px" }} />
      <Grid container xs={12} spacing={1} mb={1}>
        <Grid item xs={12} md={12}>
          <SingleInputFieldSettings
            name="wasOnPayrollOfAgency"
            orientation="horizontal"
          />
        </Grid>
      </Grid>
      <Divider sx={{ margin: "10px" }} />
      <Grid container xs={12} spacing={1} mb={1}>
        <Grid item xs={12} md={6}>
          <SingleInputFieldSettings
            name="employerStreetAddressLine1"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SingleInputFieldSettings
            name="employerStreetAddressLine2"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <SingleInputFieldSettings name="employerCityTownDistrict" />
        </Grid>

        <Grid item xs={12} md={3}>
          <SingleInputFieldSettings name="employerStateProvince" />
        </Grid>

        <Grid item xs={12} md={3}>
          <SingleInputFieldSettings name="employerCountryId" />
        </Grid>

        <Grid item xs={12} md={3}>
          <SingleInputFieldSettings name="employerZipPostalAreaCode" />
        </Grid>

        <Grid item xs={12} md={3}>
          <SingleInputFieldSettings name="employerPhoneNumber" />
        </Grid>
      </Grid>
      <Divider sx={{ margin: "10px" }} />
      <Grid container xs={12} spacing={1} mb={1}>
        <Grid item xs={12} md={3}>
          <SingleInputFieldSettings name="hrName" />
        </Grid>

        <Grid item xs={12} md={3}>
          <SingleInputFieldSettings name="hrTitle" />
        </Grid>

        <Grid item xs={12} md={3}>
          <SingleInputFieldSettings name="hrEmail" />
        </Grid>

        <Grid item xs={12} md={3}>
          <SingleInputFieldSettings name="hrPhoneNumber" />
        </Grid>
      </Grid>
      <Divider sx={{ margin: "10px" }} />
      <Grid container xs={12} spacing={1} mb={1}>
        <Grid item xs={12} md={3}>
          <SingleInputFieldSettings name="supervisorName" />
        </Grid>

        <Grid item xs={12} md={3}>
          <SingleInputFieldSettings name="supervisorTitle" />
        </Grid>

        <Grid item xs={12} md={3}>
          <SingleInputFieldSettings name="supervisorEmail" />
        </Grid>

        <Grid item xs={12} md={3}>
          <SingleInputFieldSettings name="supervisorPhoneNumber" />
        </Grid>
      </Grid>
      <Divider sx={{ margin: "10px" }} />
      <Grid container xs={12} spacing={1} alignItems="stretch">
        <Grid item xs={12} md={3}>
          <SingleInputFieldSettings name="salaryFrequencyId" />
        </Grid>

        <Grid item xs={12} md={3}>
          <SingleInputFieldSettings name="salaryCurrencyId" />
        </Grid>

        <Grid item xs={12} md={3}>
          <SingleInputFieldSettings name="salaryAmount" />
        </Grid>

        <Grid item xs={12} md={3}>
          <SingleInputFieldSettings name="otherRemuneration" />
        </Grid>

        <Grid item xs={12}>
          <SingleInputFieldSettings
            name="wasBonusReceived"
            orientation="horizontal"
          />
        </Grid>
      </Grid>
      <Divider sx={{ margin: "10px" }} />
      <Box my={1}>
        <SingleInputFieldSettings
          name="attachmentsExperienceLetter"
          orientation="horizontal"
        />
      </Box>{" "}
      <Divider sx={{ margin: "10px" }} />
      <Box my={1}>
        <SingleInputFieldSettings
          name="attachmentsPayslip"
          orientation="horizontal"
        />
      </Box>
      <Divider sx={{ margin: "10px" }} />
      <Typography my={1} fontWeight={500} textAlign="center">
        Reason of Gap
      </Typography>
      <Grid container xs={12} spacing={1} mb={1}>
        <Grid item xs={12} md={6}>
          <SingleInputFieldSettings
            name="reasonOfGapId"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SingleInputFieldSettings
            name="employmentAdditionalComments"
            orientation="horizontal"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmploymentSkeletion;
