import * as Yup from "yup";
import { createSectionSchema } from "../../utils/createSectionSchema";
import { professionalFieldsSchema } from "./allFieldsSchema";

export const professionalValidationSchema = (inputFields, initialValues) => {
  let mappingSchemaFieldsToDisplayInputFields = {
    otherProffesionalQualificationStatus: "statusProfessionalQualificationId",
  };

  let professionalSchema = createSectionSchema(
    initialValues[0],
    inputFields,
    professionalFieldsSchema,
    mappingSchemaFieldsToDisplayInputFields
  );

  return Yup.object({
    qualifications: Yup.array().of(Yup.object().shape(professionalSchema)),
  });
};
