import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Pagination, styled } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import RemoveIcon from "@mui/icons-material/Remove";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const StyledDataGrid = styled(DataGrid)(({ theme, customSorting }) => ({
  border: "0",

  "& .MuiDataGrid-columnHeaders": {
    border: `1px solid ${theme.palette.grey[700]}`,
    borderRadius: "10px",
  },

  "& .MuiDataGrid-columnHeaderTitleContainerContent": {
    width: "100%",
    justifyContent: "center",
  },

  "& .MuiDataGrid-row": {
    margin: "7px 0",
    border: `1px solid ${theme.palette.grey[700]}`,
    borderRadius: "10px",
    width: `calc(100% - 1.2px)`,
  },

  "& .MuiDataGrid-cell": {
    borderBottom: "none",
  },

  "& .MuiDataGrid-footerContainer": {
    display: "none",
  },

  // hiding mui datagrid's sort icon only when custom sorting is needed
  "& .MuiDataGrid-iconButtonContainer": {
    display: customSorting ? "none !important" : "block",
  },
}));

const TableWithCustomPagination = ({
  rowId,
  rows,
  columns,
  pageSize,
  page,
  loading,
  totalCount,
  handlePageChange,
  handleSortingApiCall = () => {},
  customSorting = false,
}) => {
  const [sortModel, setSortModel] = useState([]);
  const [customSortingModel, setCustomSortingModel] = useState({
    field: "",
    sort: "default",
  });
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setCustomSortingModel({
      field: searchParams.get("orderBy"),
      sort: searchParams.get("orderDirection") || "default",
    });
  }, [searchParams.get("orderBy"), searchParams.get("orderDirection")]);

  const handleSortModel = (model) => {
    setSortModel(model);
  };

  const handleCustomSort = (newField) => {
    let { field, sort } = customSortingModel;
    let newSort;

    //if new field is selected then we will start with 'asc' sort
    if (newField !== field) {
      newSort = "asc";
    } else {
      switch (sort) {
        case "default":
          newSort = "asc";
          break;
        case "asc":
          newSort = "desc";
          break;
        case "desc":
        default:
          newSort = "default";
          break;
      }
    }

    setCustomSortingModel({ field: newField, sort: newSort });
    handleSortingApiCall(newField, newSort === "default" ? "" : newSort);
  };

  // Customize the column headers with icons based on the sort state
  const getColumnsWithSortIcons = () =>
    columns.map((column) => ({
      ...column,
      renderHeader: (params) => {
        const isSorted = customSortingModel.field === column.field;

        if (column.sortable === false) return <>{column.headerName}</>;

        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            onClick={() => handleCustomSort(column.field)}
            position="relative"
          >
            {column.headerName}
            <Box position="absolute" top={5} right={0}>
              {isSorted && customSortingModel.sort !== "default" ? (
                customSortingModel.sort === "asc" ? (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ ml: 1 }}
                    titleAccess="ascending"
                  />
                ) : (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ ml: 1 }}
                    titleAccess="descending"
                  />
                )
              ) : (
                <RemoveIcon
                  fontSize="small"
                  sx={{ ml: 1 }}
                  titleAccess="default"
                />
              )}
            </Box>
          </Box>
        );
      },
    }));

  return (
    <Box sx={{ height: "70vh", width: "100%" }}>
      <StyledDataGrid
        getRowId={(row) => row[rowId]}
        density="compact"
        rows={rows || []}
        // columns={customSorting ? getColumnsWithSortIcons() : columns}
        columns={getColumnsWithSortIcons()}
        rowsPerPageOptions={[20]}
        disableRowSelectionOnClick
        disableColumnMenu
        editMode={false}
        pageSize={pageSize}
        hideFooterPagination={true}
        loading={loading}
        sortModel={sortModel}
        onSortModelChange={handleSortModel}
        customSorting={customSorting}
      />
      <Box display="flex" justifyContent="center" mt={1}>
        <Pagination
          size="small"
          count={Math.ceil(totalCount / pageSize)}
          page={page}
          onChange={handlePageChange}
          showFirstButton
          showLastButton
        />
      </Box>
    </Box>
  );
};

export default TableWithCustomPagination;
