import React, { memo, useCallback, useEffect } from "react";
import OperationsCandidatesSidebar from "./components/OperationsCandidatesSidebar";
import OperationsCandidatesTable from "./components/OperationsCandidatesTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getOPScandidates,
  getOPSsubCheckLists,
  getOrgsListsByOpsUserId,
} from "../../../store/actions/operationActions";
import OperationsLayout from "../Layout/OperationsLayout";
import ViewCandidateInHR from "../../../common/ViewCandidateInHR";
import {
  CUSTOM_DATE_FILTER_ERROR,
  GET_OPS_CANDIDATES,
  GET_TEAMS_BY_ORG_ID_DATA,
  SET_OPS_COMPONENT,
} from "../../../store/actions/actionTypes";
import { useSearchParams } from "react-router-dom";
import { rangeSelectors } from "../../constants/filterData";
import { getExtractPathWithParams } from "../../../utils/getExtractedPathWithParams";
import { setToastNotification } from "../../../store/actions/toastNotificationActions";
import { ERROR } from "../../../store/constant";
import moment from "moment";
import { getCurrentFileNameAndFunction } from "../../../utils/getCurrentFileNameAndFunction.js";
import { debounce } from "lodash";

const OperationsCandidates = memo(() => {
  const dispatch = useDispatch();
  const { opsComponent, OpsCandidatesList, totalCandidateCaseCount } =
    useSelector((state) => state.operations);
  const [searchParams, setSearchParams] = useSearchParams();

  let currentLoginProfile = localStorage.getItem("loginProfile");

  let { CheckMinistryUser } = JSON.parse(
    localStorage.getItem(`${currentLoginProfile}_login`)
  );

  const loggedInUser = JSON.parse(CheckMinistryUser);

  useEffect(() => {
    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "useEffect"
    );
    dispatch(
      getOrgsListsByOpsUserId({ opsUserId: loggedInUser?.usersId }, logDetails)
    );

    //to reset teams lists by org id state for updating team name in table
    return () => {
      dispatch({
        type: GET_TEAMS_BY_ORG_ID_DATA,
        payload: "reset",
      });
    };
  }, []);

  useEffect(() => {
    getFilteredCandidates();
  }, [searchParams]);

  //get candidates api call
  const opsCandidateActionCall = (filterParams) => {
    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "opsCandidateActionCall"
    );

    dispatch(getOPScandidates(filterParams, logDetails));
  };

  //debounced candidates api call
  const debouncedOpsCandidateActionCall = useCallback(
    debounce(opsCandidateActionCall, 500),
    [dispatch]
  );

  //get checks api call
  const opsChecksActionCall = (filterParams) => {
    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "opsChecksActionCall"
    );

    dispatch(getOPSsubCheckLists(filterParams, logDetails));
  };

  //debounced checks api call
  const debouncedOpsChecksActionCall = useCallback(
    debounce(opsChecksActionCall, 500),
    [dispatch]
  );

  //filter search params for fetching candidates
  let getFilteredCandidates = useCallback(() => {
    const filterParams = {
      toggledOpsTableView:
        searchParams.get("toggledOpsTableView") || "candidates",
      hrOrganizationIds: searchParams.get("hrOrganizationIds") || "all",
      verificationProcessId: searchParams.get("verificationProcessId") || "2,3",
      verificationResultId: searchParams.get("verificationResultId") || "3",
      internalStatusId:
        searchParams.get("internalStatusId") || "1,5,6,7,8,9,10,11,12",
      searchText: searchParams.get("searchText") || "",
      pageNumber: searchParams.get("pageNumber") || "1",
      fromDate:
        searchParams.get("fromDate") || rangeSelectors[1]?.dates?.fromDate,
      toDate: searchParams.get("toDate") || rangeSelectors[1]?.dates?.toDate,
      dateRange: searchParams.get("dateRange") || 1,
      orderBy: searchParams.get("orderBy") || "",
      orderDirection: searchParams.get("orderDirection") || "",
    };

    //setting params to url
    setSearchParams((prevParams) => {
      return new URLSearchParams({
        ...Object.fromEntries(prevParams.entries()),
        ...filterParams,
      });
    });

    if (
      filterParams?.hrOrganizationIds &&
      filterParams?.verificationProcessId &&
      filterParams?.verificationResultId
    ) {
      delete filterParams?.dateRange;

      if (!filterParams?.orderBy || !filterParams?.orderDirection) {
        delete filterParams?.orderBy;
        delete filterParams?.orderDirection;
      }

      const from = moment(filterParams?.fromDate);
      const to = moment(filterParams?.toDate);

      // Compare the dates
      if (from > to) {
        dispatch(
          setToastNotification(
            ERROR,
            "Please correct custom range dates before proceeding"
          )
        );
        dispatch({
          type: CUSTOM_DATE_FILTER_ERROR,
          payload: "From date should be smaller than To date",
        });

        dispatch({
          type: GET_OPS_CANDIDATES,
          payload: {
            data: OpsCandidatesList || [],
            totalCandidateCaseCount: totalCandidateCaseCount || 0,
          },
        });
      } else {
        dispatch({ type: CUSTOM_DATE_FILTER_ERROR, payload: "" });

        if (filterParams?.toggledOpsTableView === "candidates") {
          delete filterParams?.internalStatusId;
          delete filterParams?.toggledOpsTableView;

          debouncedOpsCandidateActionCall(filterParams);
        } else {
          filterParams.verificationProcessId = filterParams?.internalStatusId;
          delete filterParams?.internalStatusId;
          delete filterParams?.toggledOpsTableView;

          debouncedOpsChecksActionCall(filterParams);
        }

        localStorage.setItem(
          "OpsCandidateFilterUrl",
          JSON.stringify(getExtractPathWithParams())
        );
      }
    }
  }, [searchParams]);

  const setOpsComponent = (value) => {
    dispatch({ type: SET_OPS_COMPONENT, payload: value });
  };

  return (
    <>
      {isNaN(opsComponent) ? (
        <OperationsLayout
          SidebarComponent={<OperationsCandidatesSidebar />}
          MainComponent={
            <OperationsCandidatesTable
              setOpsComponent={setOpsComponent}
              getFilteredCandidates={getFilteredCandidates}
            />
          }
        />
      ) : (
        <ViewCandidateInHR
          candidatesCasesId={opsComponent}
          setOpsComponent={setOpsComponent}
        />
      )}
    </>
  );
});

export default OperationsCandidates;
