import { Box, Divider, Grid, Typography } from "@mui/material";
import SingleInputFieldSettings from "../components/SingleInputFieldSettings";

const QualificationSkeleton = () => {
  return (
    <Box maxWidth={1000} margin="0 auto">
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <SingleInputFieldSettings
            name="professionalQualificationTitle"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SingleInputFieldSettings
            name="qualifyingInstituteBodyName"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SingleInputFieldSettings
            name="statusProfessionalQualificationId"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SingleInputFieldSettings
            name="membershipQualificationNumber"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <SingleInputFieldSettings
            name="professionalFromDate"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <SingleInputFieldSettings
            name="professionalToDate"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <SingleInputFieldSettings
            name="dateOfAccreditation"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12}>
          <SingleInputFieldSettings
            name="professionalAdditionalComments"
            orientation="horizontal"
          />
        </Grid>
      </Grid>

      <Divider sx={{ margin: "10px" }} />

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={6}>
          <SingleInputFieldSettings
            name="professionalStreetAddressLine1"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SingleInputFieldSettings
            name="professionalStreetAddressLine2"
            orientation="horizontal"
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <SingleInputFieldSettings name="professionalCityTownDistrict" />
        </Grid>

        <Grid item xs={12} md={3}>
          <SingleInputFieldSettings name="professionalStateProvince" />
        </Grid>

        <Grid item xs={12} md={3}>
          <SingleInputFieldSettings name="professionalCountryId" />
        </Grid>

        <Grid item xs={12} md={3}>
          <SingleInputFieldSettings name="professionalZipPostalAreaCode" />
        </Grid>

        <Grid item xs={12} md={3}>
          <SingleInputFieldSettings name="institutePhoneNumber" />
        </Grid>
      </Grid>

      <Divider sx={{ margin: "10px" }} />

      <Box mb={1}>
        <SingleInputFieldSettings
          name="attachmentsProfessional"
          orientation="horizontal"
        />
      </Box>
    </Box>
  );
};

export default QualificationSkeleton;
