import { uid } from "../../../base";
import { createSectionInitialDetailsFields } from "../../utils/createSectionInitialDetailsFields";
import { checkIfFieldToBeDisplayedOrNot } from "../../utils/sectionFieldsAndLabelsUtils";

export const newEmployment = (inputFields) => {
  let allFields = {
    employmentCompanyName: "",
    isCurrentEmployer: false,
    canContactEmployer: {
      canContactEmployer: true,
      cessationDate: null,
      reasonOfChoosingLateCessationDate: "",
    },
    employmentFromDate: null,
    employmentToDate: null,
    employmentTypeId: null,
    employmentStatusId: null,
    employmentDepartment: "",
    jobTitle: "",
    employeeId: "",
    reasonForLeaving: {
      reasonForLeaving: null,
      otherReasonForLeaving: "",
      wasResignationRequested: false,
      reasonOfResignationRequested: "",
    },
    wasOnPayrollOfAgency: {
      wasOnPayrollOfAgency: false,
      agencyName: "",
      agencyStreetAddressLine1: "",
      agencyStreetAddressLine2: "",
      agencyCityTownDistrict: "",
      agencyStateProvince: "",
      agencyZipPostalAreaCode: "",
      agencyCountryId: null,
      agencyPhoneCountryCode: null,
      agencyPhoneNumber: "",
    },
    employerStreetAddressLine1: "",
    employerStreetAddressLine2: "",
    employerCityTownDistrict: "",
    employerStateProvince: "",
    employerZipPostalAreaCode: "",
    employerCountryId: null,
    employerPhoneNumber: {
      employerPhoneCountryCode: null,
      employerPhoneNumber: "",
    },
    hrName: null,
    hrTitle: null,
    hrEmail: null,
    hrPhoneNumber: { hrPhoneCountryCode: null, hrPhoneNumber: "" },
    supervisorName: null,
    supervisorTitle: null,
    supervisorEmail: null,
    supervisorPhoneNumber: {
      supervisorPhoneCountryCode: null,
      supervisorPhoneNumber: "",
    },
    salaryFrequencyId: { salaryFrequencyId: null, otherSalaryFrequency: "" },
    salaryCurrencyId: null,
    salaryAmount: "",
    otherRemuneration: "",
    wasBonusReceived: {
      wasBonusReceived: false,
      bonus: [newBonusData()],
    },
  };

  return {
    ...createSectionInitialDetailsFields(inputFields, allFields),
    attachments: [],
    candidatesEmploymentsId: uid(10),
    selectedTab: true,
    isExpanded: true,
  };
};

export const newBonusData = () => {
  return {
    bonusCurrencyId: null,
    otherBonusTypeName: "",
    bonusTypeId: null,
    bonusAmount: null,
    id: uid(10),
  };
};

export const newEmploymentGaps = (inputFields) => {
  let allGapFields = {};

  if (checkIfFieldToBeDisplayedOrNot("reasonOfGapId", inputFields)) {
    allGapFields.reasonOfGapId = null;
  }

  if (
    checkIfFieldToBeDisplayedOrNot("employmentAdditionalComments", inputFields)
  ) {
    allGapFields.employmentAdditionalComments = null;
  }

  return {
    ...allGapFields,
    candidatesEmploymentGapsId: uid(10),
  };
};
