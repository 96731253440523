import { getDynamicFieldLabelName } from "../Candidate/utils/sectionFieldsAndLabelsUtils";
import { IOSSwitch } from "./IOSSwitch";
import { Box, styled, Typography } from "@mui/material";

const StyledSwitchCard = styled(Box)`
  width: 100%;
  display: flex;
  cursor: pointer;
  min-height: 56px;
  border-radius: 12px;
  align-items: center;
  background-color: ${({ bgcolor }) => bgcolor || "#ededed"};
  justify-content: space-between;
`;

const BaseSwitchCard = ({
  label,
  box = {},
  field = {},
  form = {
    setFieldValue: () => {},
  },
  index = 0,
  ...props
}) => {
  const onToggleChecked = () => {
    form.setFieldValue(field.name, !field.value);

    // if (
    //   label ===
    //   getDynamicFieldLabelName("isCurrentEmployer", props?.inputFields, false)
    // ) {
    //   form.setFieldValue(props?.toDateFieldName, null);
    // }

    if (
      label ===
      getDynamicFieldLabelName("canContactEmployer", props?.inputFields, false)
    ) {
      form.setFieldValue(`employments.${index}.cessationDate`, null);
      form.setFieldValue(
        `employments.${index}.reasonOfChoosingLateCessationDate`,
        ""
      );
    }
  };
  return (
    <StyledSwitchCard
      {...box}
      onClick={onToggleChecked}
      p={{ xs: 2, md: "0.5rem 1.5rem 0.5rem 2rem" }}
    >
      <Typography fontWeight={500}>{label}</Typography>
      <IOSSwitch checked={field.value} />
    </StyledSwitchCard>
  );
};

export { BaseSwitchCard, StyledSwitchCard };
