import { getDynamicFieldLabelName } from "../../utils/sectionFieldsAndLabelsUtils";

export const referenceHeaders = (sectionInputFields = "") => {
  let allFields = [
    {
      value: "fullName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("fullName", sectionInputFields)
        : "Full name*",
    },
    {
      value: "title",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("title", sectionInputFields)
        : "Title*",
    },
    {
      value: "referanceCompanyName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("referanceCompanyName", sectionInputFields)
        : "Company name*",
    },
    {
      value: "professionalRelationshipName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName(
            "professionalRelationshipId",
            sectionInputFields
          )
        : "Professional Relationship With Referee*",
    },
    {
      value: "city",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("city", sectionInputFields)
        : "City",
    },
    {
      value: "countryName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("referanceCountryId", sectionInputFields)
        : "Country*",
    },
    {
      value: "email",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("email", sectionInputFields)
        : "Email*",
    },
    {
      value: "referencePhoneNumber",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("referencePhoneNumber", sectionInputFields)
        : "Phone/Contact number*",
    },
    {
      value: "referenceAdditionalComments",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName(
            "referenceAdditionalComments",
            sectionInputFields
          )
        : "Additional comment",
    },
  ];

  //for candidate section, the 'text' field will be 'undefined' if no field is present in welcome API
  //so we will filter them out to not display an empty row on review section
  let finalFields = allFields?.filter((curr) => curr?.text);

  return finalFields;
};
