import { checkIfFieldToBeDisplayedOrNot } from "../../utils/sectionFieldsAndLabelsUtils";

export const toDisplayAddressDetailsSubSection = (inputFields) => {
  let fields = [
    "professionalStreetAddressLine1",
    "professionalStreetAddressLine2",
    "professionalCityTownDistrict",
    "professionalStateProvince",
    "professionalCountryId",
    "professionalZipPostalAreaCode",
    "institutePhoneNumber",
  ];

  for (let field of fields) {
    if (checkIfFieldToBeDisplayedOrNot(field, inputFields)) {
      return true;
    }
  }
  return false;
};
