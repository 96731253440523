import * as Yup from "yup";
import "yup-phone-lite";
import {
  commonDateSchema,
  commonDropdownSchema,
  commonStateProvinceSchema,
  commonStringSchema,
  commonToDateSchema,
} from "../../utils/commonFieldSchema";

export const professionalFieldsSchema = {
  professionalQualificationTitle: (isMandatory) =>
    commonStringSchema(
      isMandatory,
      "Professional Qualification Title is required"
    ),
  qualifyingInstituteBodyName: (isMandatory) =>
    commonStringSchema(
      isMandatory,
      "Qualifying Institute Body Name is required"
    ),
  membershipQualificationNumber: (isMandatory) =>
    commonStringSchema(
      isMandatory,
      "Membership Qualification Number is required"
    ),
  professionalCountryId: (isMandatory) =>
    commonDropdownSchema(isMandatory, "Country is required"),

  statusProfessionalQualificationId: (isMandatory) =>
    commonDropdownSchema(
      isMandatory,
      "Status of Your Professional Qualification is required"
    ),

  professionalStreetAddressLine1: (isMandatory) =>
    commonStringSchema(isMandatory, "Address Line 1 is required", 100),

  professionalStreetAddressLine2: (isMandatory) =>
    commonStringSchema(isMandatory, "Address Line 2 is required", 100),
  professionalCityTownDistrict: (isMandatory) =>
    commonStringSchema(isMandatory, "City/Town/District is required", 100),

  professionalStateProvince: (isMandatory) =>
    commonStateProvinceSchema(isMandatory, "professionalCountryId"),
  professionalZipPostalAreaCode: (isMandatory) =>
    commonStringSchema(isMandatory, "Zip/Postal/Area Code is required", 100),
  dateOfAccreditation: (isMandatory) =>
    commonDateSchema(isMandatory, "Date of Accreditation is required"),

  professionalFromDate: (isMandatory) => commonDateSchema(isMandatory),

  professionalToDate: (isMandatory) =>
    commonToDateSchema(isMandatory, "professionalFromDate"),

  otherProffesionalQualificationStatus: (isMandatory) =>
    Yup.string()
      .nullable()
      .max(100, "Should not exceed 100 characters")
      .when("statusProfessionalQualificationId", {
        is: (statusProfessionalQualificationId) => {
          if (statusProfessionalQualificationId === 4) {
            return true;
          }
          return false;
        },
        then: (schema) =>
          schema
            .required("Other Professional Qualification Status is required")
            .typeError("Other Professional Qualification Status is required"),
      }),
  professionalAdditionalComments: (isMandatory) =>
    commonStringSchema(isMandatory, "Additional comment is required", 250),
};
