import { Box } from "@mui/material";
import { useEffect } from "react";
import { getActionLogCategories } from "../../../../../../../../../store/actions/operationActions";
import ActionLogCategorySelectionDropdowns from "./innerComponents/ActionLogCategorySelectionDropdowns";
import ActionLogCategoryInputFields from "./innerComponents/ActionLogCategoryInputFields";

import {
  GET_ACTION_LOG_ADDITIONAL_CATEGORIES,
  GET_ACTION_LOG_CATEGORIES_INPUTS,
  GET_ACTION_LOG_SUB_CATEGORIES,
} from "../../../../../../../../../store/actions/actionTypes";
import { getCurrencies } from "../../../../../../../../../store/actions/candidateAction";
import { getCurrentFileNameAndFunction } from "../../../../../../../../../utils/getCurrentFileNameAndFunction";
import { useDispatch, useSelector } from "react-redux";

const ActionLog = ({
  categoryDropdownFormRef,
  categoryInputsFormRef,
  setDisableSubmitBtn,
}) => {
  const { currencies } = useSelector((state) => state.candidate);

  const dispatch = useDispatch();

  const {
    actionLogCategories,
    actionLogCategoriesInputs,
    selectedCheckId,
    selectedSubCheckId,
  } = useSelector((state) => state.operations);

  //fetching categories lists
  useEffect(() => {
    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "useEffect"
    );
    
    categoryDropdownFormRef.current?.resetForm();
    categoryInputsFormRef.current?.resetForm();

    if (!actionLogCategories) {
      dispatch(getActionLogCategories(logDetails));
    }
    dispatch({
      type: GET_ACTION_LOG_SUB_CATEGORIES,
      payload: [],
    });
    dispatch({
      type: GET_ACTION_LOG_ADDITIONAL_CATEGORIES,
      payload: [],
    });
    dispatch({
      type: GET_ACTION_LOG_CATEGORIES_INPUTS,
      payload: [],
    });
    !currencies && dispatch(getCurrencies(logDetails));
  }, [selectedCheckId, selectedSubCheckId]);

  return (
    <>
      <Box mt={1}>
        {/*category selection dropdowns*/}
        <ActionLogCategorySelectionDropdowns
          categoryDropdownFormRef={categoryDropdownFormRef}
          setDisableSubmitBtn={setDisableSubmitBtn}
        />

        {/*category inputs*/}
        <Box px={2} ml={1}>
          <ActionLogCategoryInputFields
            actionLogCategoriesInputs={actionLogCategoriesInputs}
            categoryInputsFormRef={categoryInputsFormRef}
          />
        </Box>
      </Box>
    </>
  );
};

export default ActionLog;
