import * as Yup from "yup";
import "yup-phone-lite";
import {
  commonDateSchema,
  commonDropdownSchema,
  commonEmailSchema,
  commonStringSchema,
} from "../../utils/commonFieldSchema";

const nickNameRequiredSchema = Yup.object().shape({
  firstName: commonStringSchema(true, "First Name is required"),
  lastName: commonStringSchema(false, ""),
  middleName: commonStringSchema(false, ""),
});

const formerNameRequiredSchema = Yup.object().shape({
  formerName: commonStringSchema(true, "Former Name is required"),
  formerNameDateChange: commonDateSchema(true, "Date is required"),
});

const otherNameRequiredSchema = Yup.object().shape({
  otherName: commonStringSchema(true, "Other Name is required"),
  pinyinName: Yup.string()
    .nullable()
    .max(50, "Should not exceed 50 characters")
    .when("otherNameLanguageId", {
      is: (otherNameLanguageId) => otherNameLanguageId === 185,
      then: (schema) =>
        schema
          .required("Pinyin name is required")
          .typeError("Pinyin name is required"),
    }),

  otherNameLanguageId: commonDropdownSchema(true, "Field is required"),
});

export const personalParticularFieldsSchema = {
  lastName: (isMandatory) =>
    commonStringSchema(isMandatory, "Last Name is required"),
  firstName: (isMandatory) =>
    commonStringSchema(isMandatory, "First Name is required"),
  middleName: (isMandatory) =>
    commonStringSchema(isMandatory, "Middle Name is required"),
  dateOfBirth: (isMandatory) =>
    commonDateSchema(isMandatory, "Date of birth is required"),
  countryOfBirthId: (isMandatory) =>
    commonDropdownSchema(isMandatory, "Place of birth is required"),
  gender: (isMandatory) =>
    commonStringSchema(isMandatory, "Gender is required"),
  mobileNumber: (isMandatory) =>
    Yup.array().of(
      Yup.object().shape({
        mobileNumber: Yup.string()
          .nullable()
          .phone("*", "Please enter a valid phone number")
          .when([], {
            is: () => isMandatory,
            then: (schema) => schema.required("A phone number is required"),
          }),
      })
    ),
  personalEmail: (isMandatory) =>
    commonEmailSchema(isMandatory, "Personal email address is required"),
  nickNameExists: (isMandatory) => Yup.boolean().nullable(),
  otherNameExists: (isMandatory) => Yup.boolean().nullable(),
  formerNameExists: (isMandatory) => Yup.boolean().nullable(),
  nickName: (isMandatory) =>
    Yup.mixed().when("nickNameExists", {
      is: true,
      then: Yup.array().of(nickNameRequiredSchema),
    }),
  formerNames: (isMandatory) =>
    Yup.mixed().when("formerNameExists", {
      is: true,
      then: Yup.array().of(formerNameRequiredSchema),
    }),
  otherNames: (isMandatory) =>
    Yup.mixed().when("otherNameExists", {
      is: true,
      then: Yup.array().of(otherNameRequiredSchema),
    }),
  DINNumberExists: (isMandatory) => Yup.boolean(),
  DINNumber: (isMandatory) =>
    Yup.mixed().when("DINNumberExists", {
      is: true,
      then: commonStringSchema(true, "DIN number is required"),
    }),
  driverLicenseNumberExists: (isMandatory) => Yup.boolean(),
  driverLicenseNumber: (isMandatory) =>
    Yup.mixed().when("driverLicenseNumberExists", {
      is: true,
      then: commonStringSchema(true, "Driving license number is required"),
    }),
  driverLicenseFrontCopy: (isMandatory) =>
    Yup.mixed().when("driverLicenseNumberExists", {
      is: true,
      then: Yup.string()
        .nullable()
        .required("Driving license's front side is required")
        .typeError("Driving license's front side is required"),
    }),
  driverLicenseBackCopy: (isMandatory) =>
    Yup.mixed().when("driverLicenseNumberExists", {
      is: true,
      then: Yup.string()
        .nullable()
        .required("Driving license's back side is required")
        .typeError("Driving license's back side is required"),
    }),
};
