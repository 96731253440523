import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import styled from "@emotion/styled";
import _ from "lodash";
import PackageIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountries } from "../../../../store/actions/helperActions";
import { getLoggedInUserHrOrganizationId } from "../../../../utils/UserHelper";
import { getCurrentFileNameAndFunction } from "../../../../utils/getCurrentFileNameAndFunction.js";

const HrModalBox = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 10px;
  min-width: 600px;
  height: 80vh;
`;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StatusPreviewModal = ({
  open,
  handleClose,
  assignedChecks,
  assignedSubChecks,
  selectedPackageName,
}) => {
  const [toggle, setToggle] = useState(1);
  const { allCountries: countries } = useSelector((state) => state.helper);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!countries) {
      let logDetails = getCurrentFileNameAndFunction(
        import.meta.url,
        "useEffect"
      );

      dispatch(getAllCountries(logDetails));
    }
  }, []);

  const getResearchCountryList = (countryIdLists) => {
    let countryNames = [];
    countryIdLists?.forEach((countryId) => {
      let selectedCountry = countries?.find(
        (country) => +country?.countryMasterId === +countryId
      );
      countryNames.push(selectedCountry?.name);
    });
    return countryNames.join(", ");
  };
  return (
    <>
      {assignedChecks ? (
        <Dialog open={open} onClose={handleClose}>
          <HrModalBox>
            <DialogTitle>
              <Typography
                variant="h2"
                textAlign="center"
                style={{
                  fontWeight: "normal",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                }}
              >
                Package {selectedPackageName ? `- ${selectedPackageName}` : ""}
              </Typography>
            </DialogTitle>
            {/* toggle btns */}
            {assignedSubChecks ? (
              <Box display="flex" justifyContent="center">
                <Box
                  p={1}
                  sx={{
                    background: (theme) =>
                      toggle === 1 ? theme.palette.primary.main : "none",
                    color: toggle === 1 ? "#FFF" : "none",
                    borderRadius: "10px 0 0 10px",
                    border: (theme) => `1px solid ${theme.palette.grey[400]}`,
                    cursor: "pointer",
                    fontWeight: "550",
                  }}
                  onClick={() => setToggle(1)}
                >
                  Categories
                </Box>
                <Box
                  p={1}
                  sx={{
                    background: (theme) =>
                      toggle === 2 ? theme.palette.primary.main : "none",
                    color: toggle === 2 ? "#FFF" : "none",
                    borderRadius: "0 10px 10px 0",
                    border: (theme) => `1px solid ${theme.palette.grey[400]}`,
                    borderLeft: "none",
                    cursor: "pointer",
                    fontWeight: "550",
                  }}
                  onClick={() => setToggle(2)}
                >
                  Checks
                </Box>
              </Box>
            ) : null}
            <StyledDialogContent>
              {toggle === 1 ? (
                <List>
                  {assignedChecks?.map((check) => {
                    return (
                      <ListItem key={`check-${check.checkId}`}>
                        <ListItemIcon>
                          <PackageIcon color={"primary"} />
                        </ListItemIcon>
                        <ListItemText
                          primary={`${check.checkName} ${
                            check.checkId === 25
                              ? check.checkScope.allJurisdictionSearch
                                ? `- Research countries based on the candidate data - All Jurisdiction(s) in the past ${check.checkScope.noOfYearsForAdditionalJuridictionSearch} years.`
                                : `- Research country chosen: ${getResearchCountryList(
                                    check.checkScope.jurisdictionId
                                  )}`
                              : check.checkScopeDescription
                              ? "- " +
                                check.checkScopeDescription?.replace("{", "(")
                              : ""
                          }`}
                          secondary=""
                        />
                      </ListItem>
                    );
                  })}
                </List>
              ) : (
                <List>
                  {assignedSubChecks?.length ? (
                    assignedSubChecks?.map((subCheck) => {
                      return (
                        <ListItem key={`check-${subCheck.id}`}>
                          <ListItemIcon>
                            <PackageIcon color={"primary"} />
                          </ListItemIcon>
                          <ListItemText
                            primary={subCheck.subCheckDisplayName}
                            secondary=""
                          />
                        </ListItem>
                      );
                    })
                  ) : (
                    <Typography textAlign="center" fontWeight={550}>
                      Candidate Submission is Pending
                    </Typography>
                  )}
                </List>
              )}
            </StyledDialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="error"
                onClick={handleClose}
                disableElevation
              >
                Close
              </Button>
            </DialogActions>
          </HrModalBox>
        </Dialog>
      ) : null}
    </>
  );
};

export default StatusPreviewModal;
