export const refreeResponse = () => {
  return [
    {
      value: "refereeName",
      text: "Referee Name",
    },
    {
      value: "refereeEmail",
      text: "Referee Email",
    },
    {
      value: "companyName",
      text: "Company Name",
    },
    {
      value: "NotResponded",
      text: "Referee Responded?",
    },
    {
      value: "isDeclined",
      text: "Referee Accepted/Declined?",
    },
    {
      value: "reasonOfDecline",
      text: "Reason of Decline",
    },
    {
      heading: true,
      text: "Referee Answers",
    },
    {
      headerArray: true,
      text: "Referee Answers",
      value: "questionsAnswers",
      headerValues: [
        {
          value: "question",
          text: "Question",
        },
        {
          value: "answer",
          text: "Answer",
        },
      ],
    },
  ];
};
