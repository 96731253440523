export const additionalHeaders = () => {
  return [
    {
      value: "formName",
      text: "Form name",
    },
    {
      value: "savedFormName",
      text: "Your submission",
    },
  ];
};
