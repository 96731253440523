import { Box, Card, Divider, Grid } from "@mui/material";
import SingleInputFieldSettings from "../components/SingleInputFieldSettings";

const PersonalDetailsSkeleton = () => {
  return (
    <Box maxWidth={1000} margin="0 auto">
      <Grid container mb={1} gap={1} display="flex">
        <Grid item xs={12} md={6.8}>
          <Card variant="outlined">
            <Grid container spacing={1} p={1}>
              <Grid item xs={12} sm={6}>
                <SingleInputFieldSettings
                  name="firstName"
                  orientation="horizontal"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SingleInputFieldSettings
                  name="middleName"
                  orientation="horizontal"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SingleInputFieldSettings
                  name="lastName"
                  orientation="horizontal"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SingleInputFieldSettings
                  name="dateOfBirth"
                  orientation="horizontal"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SingleInputFieldSettings
                  name="countryOfBirthId"
                  orientation="horizontal"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SingleInputFieldSettings
                  name="gender"
                  orientation="horizontal"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <SingleInputFieldSettings
                  name="personalEmail"
                  orientation="horizontal"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <SingleInputFieldSettings
                  name="mobileNumber"
                  orientation="horizontal"
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box>
            <SingleInputFieldSettings
              name="otherNameExists"
              orientation="horizontal"
            />
          </Box>
          <Box mt={1}>
            <SingleInputFieldSettings
              name="formerNameExists"
              orientation="horizontal"
            />
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ margin: "10px" }} />
      <SingleInputFieldSettings
        name="nickNameExists"
        orientation="horizontal"
      />
      <Divider sx={{ margin: "10px" }} />
      <Box my={1}>
        <SingleInputFieldSettings
          name="driverLicenseNumberExists"
          orientation="horizontal"
        />
      </Box>
      <Divider sx={{ margin: "10px" }} />
      <SingleInputFieldSettings
        name="DINNumberExists"
        orientation="horizontal"
      />
    </Box>
  );
};

export default PersonalDetailsSkeleton;
