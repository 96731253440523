export const createSectionInitialDetailsFields = (inputFields, allFields) => {
  let finalFields = {};

  inputFields?.forEach(({ inputFieldKey }) => {
    if (
      typeof allFields[inputFieldKey] === "object" &&
      allFields[inputFieldKey]
    ) {
      finalFields = { ...finalFields, ...allFields[inputFieldKey] };
    } else {
      finalFields = {
        ...finalFields,
        [inputFieldKey]: allFields[inputFieldKey],
      };
    }
  });

  return finalFields;
};
