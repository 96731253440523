import { createSectionInitialDetailsFields } from "../../utils/createSectionInitialDetailsFields";

export const newProfessional = (inputFields) => {
  let allFields = {
    professionalQualificationTitle: "",
    qualifyingInstituteBodyName: "",
    statusProfessionalQualificationId: {
      statusProfessionalQualificationId: null,
      otherProffesionalQualificationStatus: "",
    },
    membershipQualificationNumber: "",
    professionalFromDate: "",
    professionalToDate: "",
    dateOfAccreditation: "",
    professionalStreetAddressLine1: "",
    professionalStreetAddressLine2: "",
    professionalCityTownDistrict: "",
    professionalStateProvince: "",
    professionalZipPostalAreaCode: "",
    professionalCountryId: null,
    institutePhoneNumber: {
      institutePhoneCountryCode: null,
      institutePhoneNumber: "",
    },
    professionalAdditionalComments: "",
  };

  return {
    ...createSectionInitialDetailsFields(inputFields, allFields),
    candidatesProfessionalQualificationsId: "",
    attachments: [],
    selectedTab: true,
    isExpanded: true,
  };
};
