export const checkIfFieldToBeDisplayedOrNot = (fieldName, inputFields) => {
  return inputFields?.find((field) => field?.inputFieldKey === fieldName);
};

export const getDynamicFieldLabelName = (
  fieldName,
  inputFields,
  showAsterisk = true
) => {
  let isPresent = inputFields?.find(
    (field) => field?.inputFieldKey === fieldName
  );

  if (isPresent) {
    let { inputFieldDisplayName, isMandatory } = isPresent;
    return `${inputFieldDisplayName}${showAsterisk && isMandatory ? "*" : ""}`;
  }
};
