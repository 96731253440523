import { FastField, Formik, setNestedObjectValues } from "formik";
import React, { useEffect, useMemo } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {
  Grid,
  Typography,
  Box,
  FormControl,
  List,
  ListItem,
  FormGroup,
  Autocomplete,
  TextField,
} from "@mui/material";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { BaseSelect } from "../../../base";

import {
  getAdditionalEmailNotificationGroups,
  getCustomEmailCategories,
  postAdditionalEmailDetails,
  putAdditionalEmailDetails,
} from "../../../../store/actions/systemAdminActions";
import { CustomCheckbox } from "../../../common/CustomCheckBox";

import MultipleUserSelectionDropdown from "./innerComponents/MultipleUserSelectionDropdown";
import MultipleOtherUsersTextFields from "./innerComponents/MultipleOtherUsersTextFields";
import { additionalEmailSettingsSchema } from "../../helpers/Schema/AdditionalEmailSettings";
import ReminderFrequency from "./innerComponents/ReminderFrequency";
import { convertFrequencyArrayIntoObject } from "../../utils/convertFrequencyArrayIntoObject";
import { frequencyUnits } from "./../../constants/frequencyUnits";
import CircularLoader from "./../../../../common/CircularLoader";
import { getCurrentFileNameAndFunction } from "../../../../utils/getCurrentFileNameAndFunction";
import { GET_ADDITIONAL_EMAIL_NOTIFICATION_GROUPS_DATA } from "../../../../store/actions/actionTypes";

const HrModalBox = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 10px;
  min-width: ${({ emailType }) => (emailType === 1 ? "50vw" : "25vw")};
  min-height: ${({ emailType }) => (emailType === 1 ? "70vh" : "25vh")};
`;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const AddAdditionalEmailSettingsModal = ({
  open,
  handleClose,
  editDetailsFlag = false,
  selectedEmailSettings = null,
  emailType = 1,
}) => {
  const {
    selectedOrg,
    additionalEmailSettingsData,
    customEmailCategoriesLists,
    additionalEmailNotificationGroupData,
  } = useSelector((state) => state.systemAdmin);
  const dispatch = useDispatch();

  useEffect(() => {
    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "useEffect"
    );

    dispatch(
      getCustomEmailCategories(
        { isSchedulable: 1, emailTypeId: emailType },
        logDetails
      )
    );

    return () => {
      dispatch({
        type: GET_ADDITIONAL_EMAIL_NOTIFICATION_GROUPS_DATA,
        payload: [],
      });
    };
  }, [emailType]);

  let checkboxesFields = useMemo(
    () => [
      { label: "Candidate", fieldName: "candidate" },
      {
        label: "HR",
        fieldName: "hr",
        displayExtraField: (props) => (
          <MultipleUserSelectionDropdown extraFieldFlag="HR" {...props} />
        ),
        toHideCheckBox: !selectedOrg,
      },
      {
        label: "OPS",
        fieldName: "ops",
        displayExtraField: (props) => (
          <MultipleUserSelectionDropdown extraFieldFlag="OPS" {...props} />
        ),
      },
      {
        label: "Other",
        fieldName: "other",
        displayExtraField: (props) => (
          <MultipleOtherUsersTextFields {...props} />
        ),
      },
    ],
    [selectedOrg]
  );

  let emailReceiversParams = [
    { title: "TO", value: "toSettings", checkboxesFields },
    { title: "CC", value: "ccSettings", checkboxesFields },
    { title: "BCC", value: "bccSettings", checkboxesFields },
  ];

  const handleEmailCategorySelect = (emailCategoryId) => {
    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "handleEmailCategorySelect"
    );

    dispatch(
      getAdditionalEmailNotificationGroups(emailCategoryId, "modal", logDetails)
    );
  };

  let additionalEmailSettingsInitialValues = useMemo(() => {
    let obj = {
      emailCategoryId: null,
      notificationGroupIds: [],
      emailTypeId: emailType,
      frequency: [{ value: null, unit: null }],
      toSettings: { candidate: false, hr: null, ops: null, other: null },
      ccSettings: { candidate: false, hr: null, ops: null, other: null },
      bccSettings: { candidate: false, hr: null, ops: null, other: null },
    };

    if (editDetailsFlag && selectedEmailSettings) {
      //for update api
      obj.frequency = selectedEmailSettings?.frequency
        ? convertFrequencyArrayIntoObject(selectedEmailSettings?.frequency)
        : [];

      obj.emailCategoryId = selectedEmailSettings?.emailCategoryId;
      obj.notificationGroupIds =
        selectedEmailSettings?.notificationGroupIds || [];
      obj.emailTypeId = selectedEmailSettings?.emailTypeId;

      emailReceiversParams?.forEach((param) => {
        param?.checkboxesFields?.forEach((field) => {
          if (selectedEmailSettings[`${param?.value}Data`]) {
            obj[param?.value][field?.fieldName] =
              selectedEmailSettings[`${param?.value}Data`][field?.fieldName];
          }
        });
      });

      handleEmailCategorySelect(selectedEmailSettings?.emailCategoryId);
    }

    return obj;
  }, [
    additionalEmailSettingsData,
    emailType,
    editDetailsFlag,
    selectedEmailSettings,
  ]);

  const handleSubmit = (values) => {
    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "handleSubmit"
    );

    let freqInHours = [];
    values?.frequency?.forEach((freq) => {
      let selectedFreqInHoursMultiplier = frequencyUnits?.find(
        (curr) => curr.unitVal === freq.unit
      )?.inHours;
      selectedFreqInHoursMultiplier &&
        freqInHours.push(freq.value * selectedFreqInHoursMultiplier);
    });

    let payload = { ...values, frequency: freqInHours };

    if (emailType === 1) {
      delete payload.notificationGroupIds;
    } else {
      delete payload.frequency;
    }

    if (editDetailsFlag && selectedEmailSettings) {
      dispatch(
        putAdditionalEmailDetails(
          payload,
          selectedOrg,
          selectedEmailSettings?.id,
          logDetails
        )
      );
    } else {
      dispatch(postAdditionalEmailDetails(payload, selectedOrg, logDetails));
    }

    handleClose();
  };

  const handleToggleCheckBoxes = (
    param,
    field,
    values,
    setFieldValue,
    setTouched
  ) => {
    setFieldValue(`${param}`, {
      ...values[param],
      [field]: Array.isArray(values[param][field])
        ? null
        : values[param][field] === null
        ? field === "other"
          ? [""]
          : []
        : !values[param][field],
    });
    setTouched(setNestedObjectValues(values, false));
  };

  return (
    <Formik
      enableReinitialize
      initialValues={additionalEmailSettingsInitialValues}
      validationSchema={additionalEmailSettingsSchema(
        emailReceiversParams,
        emailType
      )}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, submitForm, errors, touched, setTouched }) => (
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={false}
        >
          <HrModalBox emailType={emailType}>
            {customEmailCategoriesLists ? (
              <>
                <StyledDialogContent>
                  <Grid
                    container
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={4} width="100%">
                      <FastField
                        component={BaseSelect}
                        name="emailCategoryId"
                        label="Select Category*"
                        optionLabel="emailCategoryName"
                        optionValue="id"
                        options={customEmailCategoriesLists}
                        disable={editDetailsFlag && selectedEmailSettings}
                        runWhenSelect={(val) =>
                          emailType === 2 && handleEmailCategorySelect(val)
                        }
                      />
                    </Grid>
                    {emailType === 2 ? (
                      <>
                        <Grid item xs={12} md={4} width="100%" mt={2}>
                          {additionalEmailNotificationGroupData ? (
                            <Autocomplete
                              multiple
                              id="tags-outlined"
                              value={additionalEmailNotificationGroupData.filter(
                                (option) =>
                                  values?.notificationGroupIds.includes(
                                    option.id
                                  )
                              )}
                              size="small"
                              disabled={
                                !additionalEmailNotificationGroupData?.length
                              }
                              sx={{
                                maxWidth: { lg2: 800, md: 500, xs: "100%" },
                                minWidth: 300,
                              }}
                              componentsProps={{
                                popper: {
                                  modifiers: [
                                    {
                                      name: "flip",
                                      enabled: true,
                                    },
                                    {
                                      name: "preventOverflow",
                                      enabled: false,
                                    },
                                  ],
                                },
                              }}
                              onChange={(
                                event,
                                newValue = [],
                                reason,
                                details
                              ) => {
                                const finalValue = newValue.map(
                                  (option) => option.id
                                );
                                setFieldValue(
                                  "notificationGroupIds",
                                  finalValue
                                );
                              }}
                              options={additionalEmailNotificationGroupData.filter(
                                (option) =>
                                  !values?.notificationGroupIds.includes(
                                    option.id
                                  )
                              )}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              getOptionLabel={(option) =>
                                option?.groupName || ""
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Notification Group(s)"
                                  placeholder="Select Notification Group(s)"
                                  error={
                                    Boolean(touched?.notificationGroupIds) &&
                                    Boolean(errors?.notificationGroupIds)
                                  }
                                  helperText={
                                    Boolean(touched?.notificationGroupIds) &&
                                    errors?.notificationGroupIds
                                  }
                                />
                              )}
                            />
                          ) : (
                            <CircularLoader size={25} height="10vh" />
                          )}
                        </Grid>
                        <Grid item xs={12} md={3} mt="15px" width="100%">
                          {emailReceiversParams?.map((param, index) => (
                            <FormControl fullWidth size="small" key={index}>
                              <Box
                                color={(theme) => theme.palette.grey[700]}
                                fontWeight="bold"
                                textAlign="center"
                              >
                                {param.title}
                              </Box>
                              <List
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  flexWrap: "wrap",
                                }}
                              >
                                {param?.checkboxesFields?.map((field, ind) =>
                                  !field?.toHideCheckBox &&
                                  field.fieldName === "other" ? (
                                    <>
                                      <ListItem
                                        key={ind}
                                        sx={{
                                          width: "auto",
                                          maxWidth: {
                                            xs: "250px",
                                            md: "max-content",
                                          },
                                        }}
                                      >
                                        <FormGroup
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            // width: "max-content",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <CustomCheckbox
                                            type="checkbox"
                                            id={`check-checkbox-${param?.value}${field?.fieldName}`}
                                            name={
                                              [param?.value][field?.fieldName]
                                            }
                                            value={
                                              values[param?.value][
                                                field?.fieldName
                                              ]
                                                ? true
                                                : false
                                            }
                                            checked={
                                              values[param?.value][
                                                field?.fieldName
                                              ]
                                                ? true
                                                : false
                                            }
                                            onChange={() =>
                                              handleToggleCheckBoxes(
                                                param?.value,
                                                field?.fieldName,
                                                values,
                                                setFieldValue,
                                                setTouched
                                              )
                                            }
                                          />
                                          <label
                                            htmlFor={`check-checkbox-${param?.value}${field?.fieldName}`}
                                          >
                                            {" "}
                                            <Typography
                                              variant="body2"
                                              mr={1}
                                              sx={{
                                                fontSize: "12px",
                                              }}
                                            >
                                              {field?.label}
                                            </Typography>
                                          </label>
                                        </FormGroup>
                                      </ListItem>
                                      {/*hr , ops & other checkbox fields */}
                                      {values[param?.value][field?.fieldName] &&
                                      field?.displayExtraField ? (
                                        <Box my={1}>
                                          {field?.displayExtraField({
                                            values,
                                            errorMsg:
                                              errors &&
                                              errors[param?.value] &&
                                              touched &&
                                              touched[param?.value] &&
                                              touched[param?.value][
                                                field?.fieldName
                                              ] &&
                                              errors[param?.value][
                                                field?.fieldName
                                              ],
                                            param: param?.value,
                                            fieldName: field?.fieldName,
                                            setFieldValue,
                                            emailType,
                                          })}
                                        </Box>
                                      ) : null}
                                    </>
                                  ) : null
                                )}
                              </List>
                            </FormControl>
                          ))}
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={12} md={6} my={2}>
                          <Typography
                            fontWeight={550}
                            color={(theme) => theme.palette.grey[700]}
                            textAlign="center"
                          >
                            Reminder Frequency
                          </Typography>{" "}
                          <ReminderFrequency
                            fieldName="frequency"
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </Grid>
                        <Grid item xs={12} md={3} width="100%">
                          {emailReceiversParams?.map((param, index) => (
                            <FormControl fullWidth size="small" key={index}>
                              <Box
                                color={(theme) => theme.palette.grey[700]}
                                fontWeight="bold"
                                textAlign="center"
                              >
                                {param.title}
                              </Box>
                              <List
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  flexWrap: "wrap",
                                }}
                              >
                                {param?.checkboxesFields?.map((field, ind) =>
                                  !field?.toHideCheckBox ? (
                                    <>
                                      <ListItem
                                        key={ind}
                                        sx={{
                                          width: "auto",
                                          maxWidth: {
                                            xs: "250px",
                                            md: "max-content",
                                          },
                                        }}
                                      >
                                        <FormGroup
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            // width: "max-content",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <CustomCheckbox
                                            type="checkbox"
                                            id={`check-checkbox-${param?.value}${field?.fieldName}`}
                                            name={
                                              [param?.value][field?.fieldName]
                                            }
                                            value={
                                              values[param?.value][
                                                field?.fieldName
                                              ]
                                                ? true
                                                : false
                                            }
                                            checked={
                                              values[param?.value][
                                                field?.fieldName
                                              ]
                                                ? true
                                                : false
                                            }
                                            onChange={() =>
                                              handleToggleCheckBoxes(
                                                param?.value,
                                                field?.fieldName,
                                                values,
                                                setFieldValue,
                                                setTouched
                                              )
                                            }
                                          />
                                          <label
                                            htmlFor={`check-checkbox-${param?.value}${field?.fieldName}`}
                                          >
                                            {" "}
                                            <Typography
                                              variant="body2"
                                              mr={1}
                                              sx={{
                                                fontSize: "12px",
                                              }}
                                            >
                                              {field?.label}
                                            </Typography>
                                          </label>
                                        </FormGroup>
                                      </ListItem>
                                      {/*hr , ops & other checkbox fields */}
                                      {values[param?.value][field?.fieldName] &&
                                      field?.displayExtraField ? (
                                        <Box my={1}>
                                          {field?.displayExtraField({
                                            values,
                                            errorMsg:
                                              errors &&
                                              errors[param?.value] &&
                                              touched &&
                                              touched[param?.value] &&
                                              touched[param?.value][
                                                field?.fieldName
                                              ] &&
                                              errors[param?.value][
                                                field?.fieldName
                                              ],
                                            param: param?.value,
                                            fieldName: field?.fieldName,
                                            setFieldValue,
                                            emailType,
                                          })}
                                        </Box>
                                      ) : null}
                                    </>
                                  ) : null
                                )}
                              </List>
                            </FormControl>
                          ))}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </StyledDialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleClose}
                    disableElevation
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color={
                      editDetailsFlag && selectedEmailSettings
                        ? "warning"
                        : "primary"
                    }
                    type="submit"
                    disableElevation
                    onClick={submitForm}
                  >
                    {editDetailsFlag && selectedEmailSettings
                      ? "Update"
                      : "Add"}{" "}
                  </Button>
                </DialogActions>{" "}
              </>
            ) : (
              <Box height="75vh">
                <CircularLoader height="100%" size={50} />
              </Box>
            )}
          </HrModalBox>
        </Dialog>
      )}
    </Formik>
  );
};

export default AddAdditionalEmailSettingsModal;
