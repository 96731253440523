import * as Yup from "yup";
import "yup-phone-lite";
import { createSectionSchema } from "../../utils/createSectionSchema";
import { professionalReferenceFieldsSchema } from "./allFieldsSchema";

export const referenceValidationSchema = (
  refCount,
  minNoOfRefrees,
  reasonForLessNoOfReference,
  inputFields,
  references
) => {
  let referenceSchema = createSectionSchema(
    references[0],
    inputFields,
    professionalReferenceFieldsSchema
  );

  return Yup.object().shape(
    {
      noOfProfessionalReference: Yup.number().required(
        "Number of Professional Reference is required"
      ),
      reasonForLessNoOfReference: Yup.string()
        .nullable()
        .max(250, "Should not exceed 250 characters")
        .when("reasonForLessNoOfReference", {
          is: () => {
            if (
              refCount < minNoOfRefrees &&
              reasonForLessNoOfReference === ""
            ) {
              return true;
            } else {
              return false;
            }
          },
          then: (schema) =>
            schema.required("Field is required").typeError("Field is required"),
        }),
      references: Yup.array().of(Yup.object().shape(referenceSchema)),
    },
    [["reasonForLessNoOfReference", "reasonForLessNoOfReference"]]
  );
};
