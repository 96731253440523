import { createSectionInitialDetailsFields } from "../../utils/createSectionInitialDetailsFields";

export const newAddress = (inputFields) => {
  let allFields = {
    addressFromDate:  "" ,
    addressToDate: "" ,
    streetAddressLine1: "",
    streetAddressLine2: "",
    cityTownDistrict: "",
    stateProvince: "",
    zipPostalAreaCode: "",
    addressCountryId:  null ,
  };

  let fieldsToBeDisplayed = createSectionInitialDetailsFields(
    inputFields,
    allFields
  );

  return { ...fieldsToBeDisplayed, isExpanded: true, selectedTab: true };
};
