import { getDynamicFieldLabelName } from "../../utils/sectionFieldsAndLabelsUtils";

export const identityHeaders = (sectionInputFields = "") => {
  let allFields = [
    {
      value: "primaryNationalityName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("primaryNationalityId", sectionInputFields)
        : "Primary Nationality*",
    },
    {
      value: "residenceCountryName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("residenceCountryId", sectionInputFields)
        : "Primary Permanent Residency Country*",
    },
    {
      value: "birthCountryName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("birthCountryId", sectionInputFields)
        : "Country of Birth*",
    },
    {
      value: "identityDocumentType",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("identityDocumentType", sectionInputFields)
        : "Document Type*",
    },
    {
      value: "identityDocumentNumber",
      text: "Document Number*",
    },
    {
      value: "identityAttachments",
      text: "Uploaded Documents*",
    },
  ];

  //for candidate section, the 'text' field will be 'undefined' if no field is present in welcome API
  //so we will filter them out to not display an empty row on review section
  let finalFields = allFields?.filter((curr) => curr?.text);

  return finalFields;
};
