import { getDynamicFieldLabelName } from "../../utils/sectionFieldsAndLabelsUtils";

export const addressHeaders = (sectionInputFields = "") => {
  let allFields = [
    {
      value: "streetAddressLine1",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("streetAddressLine1", sectionInputFields)
        : "Address Line 1*",
    },
    {
      value: "streetAddressLine2",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("streetAddressLine2", sectionInputFields)
        : "Address Line 2",
    },
    {
      value: "cityTownDistrict",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("cityTownDistrict", sectionInputFields)
        : "City/Town/District*",
    },
    {
      value: "stateProvince",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("stateProvince", sectionInputFields)
        : "State/Province*",
    },
    {
      value: "countryName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("addressCountryId", sectionInputFields)
        : "Country*",
    },
    {
      value: "zipPostalAreaCode",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("zipPostalAreaCode", sectionInputFields)
        : "Zip/Postal/Area Code",
    },
    {
      value: "addressFromDate",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("addressFromDate", sectionInputFields)
        : "From date*",
    },
    {
      value: "addressToDate",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("addressToDate", sectionInputFields)
        : "To date*",
    },
  ]

  //for candidate section, the 'text' field will be 'undefined' if no field is present in welcome API 
  //so we will filter them out to not display an empty row on review section
  let finalFields = allFields?.filter((curr) => curr?.text);

  return finalFields
};
