import { getDynamicFieldLabelName } from "../../utils/sectionFieldsAndLabelsUtils";

export const personalParticularHeaders = (sectionInputFields = "") => {
  let allFields = [
    {
      value: "firstName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("firstName", sectionInputFields)
        : "First name*",
    },
    {
      value: "middleName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("middleName", sectionInputFields)
        : "Middle name",
    },
    {
      value: "lastName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("lastName", sectionInputFields)
        : "Last name*",
    },
    {
      value: "gender",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("gender", sectionInputFields)
        : "Gender*",
    },
    {
      value: "dateOfBirth",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("dateOfBirth", sectionInputFields)
        : "Date of birth*",
    },
    {
      value: "countryOfBirthName",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("countryOfBirthId", sectionInputFields)
        : "Place of birth*",
    },
    {
      value: "personalEmail",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("personalEmail", sectionInputFields)
        : "Personal email address*",
    },
    {
      value: "mobileNumber",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("mobileNumber", sectionInputFields)
        : "Phone number*",
    },
    {
      heading: true,
      text: "Other Names",
    },
    {
      value: "otherNameExists",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("otherNameExists", sectionInputFields, false)
        : "Do you have name in other language?",
    },
    {
      headerArray: true,
      text: "Other Names",
      value: "otherNames",
      headerValues: [
        {
          value: "otherNameLanguageName",
          text: "Selected language*",
        },
        {
          value: "otherName",
          text: "Name in selected language*",
        },
        {
          value: "pinyinName",
          text: "Name In Pinyin*",
        },
      ],
    },
    {
      heading: true,
      text: "Former Names",
    },
    {
      value: "formerNameExists",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName(
            "formerNameExists",
            sectionInputFields,
            false
          )
        : "Do you have any former name?",
    },
    {
      headerArray: true,
      text: "Former Names",
      value: "formerNames",
      headerValues: [
        {
          value: "formerName",
          text: "Former name*",
        },
        {
          value: "formerNameDateChange",
          text: "Effective date of the name change*",
        },
      ],
    },
    {
      heading: true,
      text: "Nick Names",
    },
    {
      value: "nickNameExists",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("nickNameExists", sectionInputFields)
        : "Do you have any alias/nickname?*",
    },
    {
      headerArray: true,
      text: "Nick Names",
      value: "nickName",
      headerValues: [
        {
          value: "NickNameFirstName",
          text: "Nick Name*",
        },
      ],
    },
    {
      heading: true,
      text: "Driver License Details",
    },
    {
      value: "driverLicenseNumberExists",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName(
            "driverLicenseNumberExists",
            sectionInputFields
          )
        : "Do you have a valid driver license?*",
    },
    { value: "driverLicenseNumber", text: "Driving License Number*" },
    {
      value: "driverLicenseFrontCopy",
      text: "Driving License's Front Copy*",
    },
    { value: "driverLicenseBackCopy", text: "Driving License's Back Copy*" },
    {
      heading: true,
      text: "DIN Number Details",
    },
    {
      value: "DINNumberExists",
      text: sectionInputFields?.length
        ? getDynamicFieldLabelName("DINNumberExists", sectionInputFields)
        : "Are you Director in any Company resisted with MCA(Ministry of Corporate Affairs) OR have a valid DIN number?*",
    },
    { value: "DINNumber", text: "DIN Number*" },
  ];

  //for candidate section, the 'text' field will be 'undefined' if no field is present in welcome API
  //so we will filter them out to not display an empty row on review section
  let finalFields = allFields?.filter((curr) => curr?.text);

  return finalFields;
};
