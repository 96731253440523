import { checkIfFieldToBeDisplayedOrNot } from "../../utils/sectionFieldsAndLabelsUtils";

export const toDisplaySupervisorDetailsSubSection = (inputFields) => {
  const fields = [
    "supervisorName",
    "supervisorTitle",
    "supervisorEmail",
    "supervisorPhoneNumber",
  ];

  for (let field of fields) {
    if (checkIfFieldToBeDisplayedOrNot(field, inputFields)) {
      return true;
    }
  }
  return false;
};
