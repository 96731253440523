import * as Yup from "yup";
import { commonDropdownSchema } from "../../utils/commonFieldSchema";

export const identityFieldsSchema = (identityDocumentTypeLists) => ({
  birthCountryId: (isMandatory) =>
    commonDropdownSchema(isMandatory, "Field is required"),

  primaryNationalityId: (isMandatory) =>
    commonDropdownSchema(isMandatory, "Field is required"),

  residenceCountryId: (isMandatory) =>
    commonDropdownSchema(isMandatory, "Field is required"),

  identityDocumentType: (isMandatory) =>
    Yup.string()
      .nullable()
      .when([], {
        is: () => {
          return Boolean(identityDocumentTypeLists?.length) && isMandatory;
        },
        then: (schema) =>
          schema.required("Field is required!").typeError("Field is required!"),
        otherwise: (schema) => schema,
      }),
  identityDocumentNumber: (isMandatory) =>
    Yup.string()
      .nullable()
      .max(50, "Should not exceed 50 characters")
      .when('identityDocumentType', {
        is: (identityDocumentType) => {
          return identityDocumentType;
        },
        then: (schema) =>
          schema.required("Field is required!").typeError("Field is required!"),
        otherwise: (schema) => schema,
      }),
});
